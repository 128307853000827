import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import { FormControl, MenuItem } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormGroup from "@material-ui/core/FormGroup";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { Transition } from "../../../settings/Settings";
import { updateTracker } from "../../../../../store/actions/checkInCheckOutActions";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "1em 0",
    },
    expand: {
        transform: "rotate(0deg)",
        marginLeft: "auto",
        transition: theme.transitions.create("transform", {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: "rotate(180deg)",
    },
}));

const TrackerUpdateModal = ({
    open,
    handleClose,
    onTrackerUpdate,
    tracker,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [
        isUpdateConfirmationDialogOpen,
        setIsUpdateConfirmationDialogOpen,
    ] = useState(false);

    const [form, setForm] = useState(tracker);
    const setFormValue = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.value,
        });
    };

    const handleUpdate = async () => {
        if (validate()) {
            setIsLoading(true);
            const success = await dispatch(updateTracker(tracker._id, form));
            if (success) {
                dispatch(
                    addToNotifications({
                        type: "SUCCESS",
                        message: translate("Tracker updated successfully!"),
                        size: "md",
                    })
                );
                handleClose();
                setIsLoading(false);
                await onTrackerUpdate();
            } else {
                setIsLoading(false);
                setIsUpdateConfirmationDialogOpen(false);
            }
        } else setIsUpdateConfirmationDialogOpen(false);
    };

    const validate = useCallback(() => {
        const errors = form.user.length === 0;
        if (errors) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Please fill the required fields!"),
                    size: "md",
                })
            );
            return false;
        } else return true;
    }, [form]);

    useEffect(() => {
        setForm(tracker);
    }, [tracker]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={false}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"sm"}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate("Tracker")}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate("Update tracker")}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            label={translate("UserId")}
                            placeholder={translate("Enter user's Id")}
                            required
                            value={form.user}
                            onChange={setFormValue("user")}
                            variant="outlined"
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <TextField
                            id="outlined-select-role"
                            select
                            required
                            label={translate("Tracker Type")}
                            value={form.type || ""}
                            onChange={setFormValue("type")}
                            helperText={translate("Select tracker type")}
                            variant="outlined"
                        >
                            <MenuItem value={"checkIn"}>
                                {translate("Check In")}
                            </MenuItem>
                            <MenuItem value={"checkOut"}>
                                {translate("Check Out")}
                            </MenuItem>
                        </TextField>
                    </FormControl>
                </FormGroup>

                <DialogActions className={classes.button}>
                    <Button
                        onClick={handleClose}
                        disabled={isLoading}
                        color="primary"
                    >
                        {translate("Abort")}
                    </Button>
                    <Button
                        onClick={() => setIsUpdateConfirmationDialogOpen(true)}
                        disabled={isLoading}
                        color="primary"
                        variant={"contained"}
                    >
                        {translate("Update")}
                    </Button>
                </DialogActions>
                <ConfirmationModal
                    open={isUpdateConfirmationDialogOpen}
                    handleClose={() => setIsUpdateConfirmationDialogOpen(false)}
                    handleSuccess={handleUpdate}
                    title={translate("Update Tracker")}
                    message={translate(
                        "Are you sure you want to update this tracker?"
                    )}
                    abortText={translate("No")}
                    successText={translate("Yes")}
                    isLoading={isLoading}
                />
            </DialogContent>
        </Dialog>
    );
};
TrackerUpdateModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    onTrackerUpdate: PropTypes.func.isRequired,
    tracker: PropTypes.object.isRequired,
};

export default TrackerUpdateModal;
