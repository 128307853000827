import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, IconButton, InputAdornment } from "@material-ui/core";
import { translate } from "../../../../../utils/i18n";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment-timezone";
import {
    getEvents,
    listenForSignInChange,
} from "../../../../../store/actions/calendarActions";
import ScheduleMeetingModal from "./ScheduleMeetingModal";
import MeetingCard from "./MeetingCard";
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { Spin } from "antd";
import EventIcon from "@material-ui/icons/Event";
import reactStringReplace from "react-string-replace";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    skeleton: {
        height: "45em",
        overflowY: "auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    updateIcon: {
        marginLeft: "1.5em",
    },
    button: {
        color: "rgba(0, 0, 0, 0.65)",
        marginRight: "1em",
    },
    titleText: {
        fontSize: "1.8rem",
        margin: "0 0.75em",
    },
    title: {
        margin: "2em 0",
        display: "flex",
        alignItems: "center",
    },
    cardContainer: {
        width: "98%",
        marginLeft: "1em",
        maxHeight: "85vh",
        overflowY: "auto",
    },
}));

const Meeting = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);

    const [isSignedIn, setIsSignedIn] = useState(false);
    const [meetingModalOpen, setMeetingModalOpen] = useState(false);
    const [events, setEvents] = useState({});
    const [timeMin, setTimeMin] = useState(
        new Date(moment(new Date()).format("YYYY/MM/DD"))
    );
    const [timeMax, setTimeMax] = useState(
        new Date(moment().add(30, "days").format("YYYY/MM/DD"))
    );

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isGapiReady)
            dispatch(
                listenForSignInChange((isLoggedIn) => setIsSignedIn(isLoggedIn))
            );
    }, [isGapiReady]);

    useEffect(() => {
        getCalendarEvents();
    }, [isSignedIn]);

    const handleAuth = () => {
        if (isSignedIn) {
            window.gapi.auth2.getAuthInstance().signOut();
            setEvents({});
        } else window.gapi.auth2.getAuthInstance().signIn();
    };

    const getCalendarEvents = useCallback(
        async (timeMin_ = timeMin, timeMax_ = timeMax) => {
            setIsLoading(true);
            if (isSignedIn) {
                const events = await dispatch(getEvents(timeMin_, timeMax_));
                if (events) setEvents(events);
            }
            setIsLoading(false);
        },
        [timeMin, timeMax, isSignedIn]
    );

    const handleMeetingModalClose = useCallback(
        () => setMeetingModalOpen(false),
        []
    );

    const renderTitle = useCallback(() => {
        const title = translate("Showing events from <start> to <end>");
        const getJSXElement = (type, index) => {
            switch (type) {
                case "<start>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            disablePast
                            ampm={false}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMin}
                            disabled={!isSignedIn}
                            onChange={setTimeMin}
                            onAccept={(date) =>
                                getCalendarEvents(date, timeMax)
                            }
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={!isSignedIn}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            showTodayButton
                        />
                    );
                case "<end>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            disablePast
                            ampm={false}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMax}
                            disabled={!isSignedIn}
                            onChange={setTimeMax}
                            onAccept={(date) =>
                                getCalendarEvents(timeMin, date)
                            }
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton disabled={!isSignedIn}>
                                            <EventIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            showTodayButton
                        />
                    );
                default:
                    return <></>;
            }
        };

        const _title = reactStringReplace(
            title,
            /(<start>|<end>)/g,
            (match, i) => getJSXElement(match, i)
        );
        return _title.map((element, index) => {
            if (typeof element === "string")
                return (
                    <span className={classes.titleText} key={index}>
                        {element}
                    </span>
                );
            else return element;
        });
    }, [
        timeMin,
        isSignedIn,
        setTimeMin,
        getCalendarEvents,
        timeMax,
        setTimeMax,
    ]);

    return (
        <div className={classes.root}>
            <section
                style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginRight: "4em",
                    marginTop: "1em",
                }}
            >
                <div>
                    <Button
                        className={classes.button}
                        onClick={() => setMeetingModalOpen(true)}
                        size="large"
                        variant={"outlined"}
                        disabled={!isGapiReady || !isSignedIn}
                    >
                        <ScheduleIcon />
                        <div style={{ marginLeft: "0.5em" }}>
                            {translate("Schedule Meeting")}
                        </div>
                    </Button>

                    <Button
                        className={classes.button}
                        onClick={handleAuth}
                        size="large"
                        variant={"outlined"}
                        disabled={!isGapiReady}
                    >
                        <AccountCircleIcon />
                        <div style={{ marginLeft: "0.5em" }}>
                            {translate(isSignedIn ? "Sign Out" : "Sign In")}
                        </div>
                    </Button>
                </div>
            </section>
            <div className={classes.title}>{renderTitle()}</div>
            {isLoading ? (
                <div className={classes.skeleton}>
                    <Spin />
                </div>
            ) : (
                <Grid
                    container
                    spacing={3}
                    className={classes.cardContainer}
                    id={"chatContent"}
                >
                    {events.items?.map((event, index) => (
                        <Grid item xs={3} key={index}>
                            <MeetingCard
                                event={event}
                                onEventAddOrUpdate={getCalendarEvents}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
            {!isLoading && events.items && events.items.length === 0 && (
                <div className={classes.skeleton}>{translate("No events")}</div>
            )}

            <ScheduleMeetingModal
                open={meetingModalOpen}
                handleClose={handleMeetingModalClose}
                onEventAddOrUpdate={getCalendarEvents}
            />
        </div>
    );
};

export default Meeting;
