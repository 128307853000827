import { usePhotoSettings } from "./usePhotoSettings";
import { useAvatarSettings } from "./useAvatarSettings";
import { useTextSettings } from "./useTextSettings";
import { useThemeSettings } from "./useThemeSettings";
import { useState } from "react";
import {languageSelectionLabels} from "../../../styles/constants";
import {useMapSettings} from "./useMapSettings";

export const useAppearanceSettings = (
    tabIndex,
    appearanceSettingsRef,
    tabValueRef,
    open
) => {
    // logo photo
    const logoSettings = usePhotoSettings(
        "logo",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );

    // background photo
    const backgroundSettings = usePhotoSettings(
        "backgrounds",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open,
        true
    );
    // let backgroundSettings = usePhotoSettings(null, null, 1, "backgrounds", appearanceSettingsRef, tabValueRef, true)

    // avatar
    const avatarSettings = useAvatarSettings(
        true,
        true,
        "high",
        "avatar",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );
    // title
    const titleSettings = useTextSettings(
        "AimesoftReception",
        null,
        null,
        null,
        "title",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );
    // company name
    const companySettings = useTextSettings(
        "Aimesoft JSC",
        null,
        null,
        null,
        "companyName",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );
    // special label
    const specialLabelSettings = useTextSettings(
        languageSelectionLabels.join("<br/>"),
        null,
        null,
        null,
        "specialLabel",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );

    // theme
    const themeSettings = useThemeSettings(
        "theme",
        tabIndex,
        appearanceSettingsRef,
        tabValueRef,
        open
    );

    const mapSettings = useMapSettings("map", tabIndex, appearanceSettingsRef, tabValueRef, open)

    // delete appearance setting(restore default)
    const [
        appearanceDeletionDialogOpen,
        setAppearanceDeletionDialogOpen,
    ] = useState(false);

    const hasAppearanceSettingsChanged = () => {
        const {
            title: title_,
            companyName: companyName_,
            specialLabel: specialLabel_,
            avatar: avatar_,
            theme: theme_,
            selectedBackground: selectedBackground_,
        } = appearanceSettingsRef.current;

        const hasTitleSettingsChanged =
            titleSettings.text !== title_.text ||
            titleSettings.fontSize !== title_.styles.fontSize ||
            titleSettings.currentColor !== title_.styles.color ||
            titleSettings.fontFamily !== title_.styles.fontFamily;
        const hasCompanySettingsChanged =
            companySettings.text !== companyName_.text ||
            companySettings.fontSize !== companyName_.styles.fontSize ||
            companySettings.currentColor !== companyName_.styles.color ||
            companySettings.fontFamily !== companyName_.styles.fontFamily;
        const hasSpecialLabelSettingsChanged =
            specialLabelSettings.text !== specialLabel_.text ||
            specialLabelSettings.fontSize !== companyName_.styles.fontSize ||
            specialLabelSettings.currentColor !== specialLabel_.styles.color ||
            specialLabelSettings.fontFamily !== specialLabel_.styles.fontFamily;
        const hasAvatarSettingsChanged =
            avatarSettings.isAvatarOn !== avatar_.status ||
            avatarSettings.avatarQuality !== avatar_.quality ||
            avatarSettings.isAvatarAnimationOn !== avatar_.animation ||
            avatarSettings.selectedAnimation !== avatar_.selectedAnimation;
        const hasThemeSettingsChanged =
            themeSettings.primaryColor !== theme_.primaryColor ||
            themeSettings.primaryButtonTheme !== theme_.primaryButtonTheme ||
            themeSettings.secondaryButtonTheme !==
                theme_.secondaryButtonTheme ||
            themeSettings.primaryButtonTextTheme !==
                theme_.primaryButtonTextTheme ||
            themeSettings.secondaryButtonTextTheme !==
                theme_.secondaryButtonTextTheme ||
            themeSettings.secondaryColor !== theme_.secondaryColor;
        const hasSelectedBackgroundChanged =
            selectedBackground_ !== backgroundSettings.selectedBackground;
        // console.log(hasTitleSettingsChanged, hasCompanySettingsChanged, hasSpecialLabelSettingsChanged, hasAvatarSettingsChanged, hasThemeSettingsChanged, hasSelectedBackgroundChanged);
        return (
            hasTitleSettingsChanged ||
            hasCompanySettingsChanged ||
            hasSpecialLabelSettingsChanged ||
            hasAvatarSettingsChanged ||
            hasThemeSettingsChanged ||
            hasSelectedBackgroundChanged ||
            mapSettings.hasMapSettingsChanged()
        );
    };
    return {
        logoSettings,
        backgroundSettings,
        avatarSettings,
        titleSettings,
        companySettings,
        specialLabelSettings,
        themeSettings,
        mapSettings,
        appearanceDeletionDialogOpen,
        setAppearanceDeletionDialogOpen,
        hasAppearanceSettingsChanged,
    };
};
