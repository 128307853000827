import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    try {
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return undefined;
            }
        }
    } catch (e) {
        return undefined;
    }
    return o;
};

export const useApplicationSettings = (fields) => {
    // APPLICATION
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const applicationSettings = useSelector(
        (state) => state.settings.applicationSettings
    );
    const applicationSettingsRef = useRef(applicationSettings);
    applicationSettingsRef.current = applicationSettings;
    const [firstLoad, setFirstLoad] = useState(true);
    const [applicationSettingsReturn, setApplicationSettingsReturn] = useState(
        {}
    );

    const overrideDefaultApplicationSettings = () => {
        let timer = null;
        if (applicationSettingsRef.current !== null) {
            const newReturn = { ...applicationSettingsReturn };
            for (const field of fields) {
                const newFieldName = field.replace(".", "_");
                newReturn[newFieldName] =
                    Object.byString(applicationSettingsRef.current, field) ||
                    undefined;
            }
            setApplicationSettingsReturn(newReturn);
        } else timer = setTimeout(overrideDefaultApplicationSettings, 1000);
        return timer;
    };

    useEffect(() => {
        if (updateSettings || firstLoad) {
            const timer = overrideDefaultApplicationSettings();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return applicationSettingsReturn;
};
