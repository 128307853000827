import axios from "axios";

import {
    ADD_NOTIFICATION,
    SET_APPEARANCE_SETTINGS,
    SET_APPLICATION_SETTINGS,
    SET_IS_THEME_OVERRIDDEN,
    SET_KBOT_BUTTON_CONFIGS,
    SET_UPDATE_SETTINGS_FROM_DB,
} from "./types";
import { translate } from "../../utils/i18n";
import {getCurrentBotId, } from "./chatActions";

// get appearance settings
export const getAppearanceSettings = () => async (dispatch) => {
    const botId = await getCurrentBotId();
    await axios
        .get(`/api/v1/appearances?botId=${botId}`)
        .then(async (res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_APPEARANCE_SETTINGS,
                    appearanceSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Got Appearance Settings!"),
                        size: "md",
                    },
                });
            } else if (res.data.error.includes("Could not find appearance settings")) {
                console.log(`creating`, botId);
                dispatch(createAppearanceSettings(botId))
            }else{
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// create appearance settings
export const createAppearanceSettings = (botId) => async (dispatch) => {
    await axios
        .post(`/api/v1/appearances?botId=${botId}`)
        .then((res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_APPEARANCE_SETTINGS,
                    appearanceSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Appearance settings updated!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// update appearance settings
export const updateAppearanceSettings = (appearanceSettings) => async (
    dispatch
) => {
    await axios
        .put("/api/v1/appearances", appearanceSettings, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        })
        .then((res) => {
            // console.log(res.data);
            if (res.data.success) {
                dispatch({
                    type: SET_APPEARANCE_SETTINGS,
                    appearanceSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Appearance Settings updated!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// Delete appearance settings
export const deleteAppearanceSettings = () => async (dispatch) => {
    const botId = await getCurrentBotId();
    await axios
        .delete(`/api/v1/appearances?botId=${botId}`)
        .then(async (res) => {
            // console.log(res.data);
            if (res.data.success) {
                await timeout(2000);
                await dispatch(createAppearanceSettings(botId));
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Appearance settings deleted!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// get application settings
export const getApplicationSettings = () => async (dispatch) => {
    const botId = await getCurrentBotId();
    await axios
        .get(`/api/v1/applications?botId=${botId}`)
        .then((res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_APPLICATION_SETTINGS,
                    applicationSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Got Application Settings!"),
                        size: "md",
                    },
                });
            } else if (res.data.error.includes("Could not find application settings")) {
                    dispatch(createApplicationSettings(botId))
            }
            else{
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// create appearance settings
export const createApplicationSettings = (botId) => async (dispatch) => {
    await axios
        .post(`/api/v1/applications?botId=${botId}`)
        .then((res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_APPLICATION_SETTINGS,
                    applicationSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Application settings updated!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// update appearance settings
export const updateApplicationSettings = (applicationSettings) => async (
    dispatch
) => {
    await axios
        .put("/api/v1/applications", applicationSettings, {
            maxContentLength: Infinity,
            maxBodyLength: Infinity,
        })
        .then((res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_APPLICATION_SETTINGS,
                    applicationSettings: res.data.data,
                });
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Application Settings updated!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

// Delete appearance settings
export const deleteApplicationSettings = () => async (dispatch) => {
    const botId = await getCurrentBotId();
    await axios
        .delete(`/api/v1/applications?botId=${botId}`)
        .then(async (res) => {
            console.log(res);
            if (res.data.success) {
                await dispatch(createApplicationSettings(botId));
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: translate("Application settings deleted!"),
                        size: "md",
                    },
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

export const updateSettingsFromDB = () => async (dispatch) => {
    await dispatch({
        type: SET_UPDATE_SETTINGS_FROM_DB,
        updateSettings: true,
    });
    await timeout(1000);

    dispatch({
        type: SET_UPDATE_SETTINGS_FROM_DB,
        updateSettings: false,
    });
};

export const setIsThemeOverridden = (isThemeOverridden) => (dispatch) => {
    dispatch({
        type: SET_IS_THEME_OVERRIDDEN,
        isThemeOverridden,
    });
};

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const loadKbotButtonConfigs = () => async (dispatch) => {
    const botId = await getCurrentBotId();
    await axios
        .get(`/api/v1/kbot-button?id=${botId}`)
        .then((res) => {
            // console.log(res);
            if (res.data.success) {
                dispatch({
                    type: SET_KBOT_BUTTON_CONFIGS,
                    kbotButtonConfigs: res.data.data,
                });
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

export const overwriteKbotButtonConfigs = (kbotButtonConfigs) => async (
    dispatch
) => {
    const botId = await getCurrentBotId();
    await axios
        .put(`/api/v1/kbot-button?id=${botId}`, kbotButtonConfigs)
        .then(async (res) => {
            // console.log(res);
            if (res.data.success) {
                await dispatch(loadKbotButtonConfigs());
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: res.data.error,
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};
