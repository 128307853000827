import { useCallback, useEffect, useState } from "react";
import {
    slack as slack_,
    languageCodes,
    defaultVoiceResponses,
    defaultWelcomeText,
    defaultReceptionScreenVoice,
} from "../../../styles/constants";
import {
    areArraysSame,
    areObjectsSame,
} from "../../../store/actions/chatActions";
import { useDispatch, useSelector } from "react-redux";
import { unFreezeObjOrArray } from "./useAvatarSettings";
import { translate } from "../../../utils/i18n";
import { ADD_NOTIFICATION } from "../../../store/actions/types";
import { bots } from "../../../config.json";
import { updateObject } from "./useMapSettings";

export const useApplicationSettings = (
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    const dispatch = useDispatch();
    // APPLICATION
    const [isCameraOn, setIsCameraOn] = useState(true);
    const [showCamera, setShowCamera] = useState(true);

    const [showFaceFrame, setShowFaceFrame] = useState(true);

    const [faceRecognitionEnabled, setFaceRecognitionEnabled] = useState(true);
    const [faceRecognitionServer, setFaceRecognitionServer] = useState(
        process.env.REACT_APP_RECOGNITION_AND_REGISTER_API
    );

    const [minDetectionArea, setMinDetectionArea] = useState(
        parseInt(process.env.REACT_APP_MIN_DETECTION_AREA)
    );

    const [doorEnabled, setDoorEnabled] = useState(true);
    const [doorServer, setDoorServer] = useState(
        process.env.REACT_APP_DOOR_API
    );

    const [microphoneOn, setMicrophoneOn] = useState(true);

    const [maxAudioAge, setMaxAudioAge] = useState(null);
    const [maxCacheSize, setMaxCacheSize] = useState(null);

    const [receptionistVoiceEnabled, setReceptionistVoiceEnabled] =
        useState(true);
    const [receptionistVoiceGender, setReceptionistVoiceGender] =
        useState("female");
    const [receptionistVoiceEndpoints, setReceptionistVoiceEndpoints] =
        useState(null);

    const [modalType, setModalType] = useState({ field: "delivery" });
    const [staffName, setStaffName] = useState("");
    const [staffSlackId, setStaffSlackId] = useState("");

    const [slackToken, setSlackToken] = useState(
        process.env.REACT_APP_API_SLACK_TOKEN
    );
    const [slack, setSlack] = useState({
        delivery: {
            staffs: slack_.DELIVERY_STAFFS,
            channels: slack_.DELIVERY_CHANNELS,
            message: slack_.DELIVERY_MESSAGE,
            staffModal: false,
        },
        meeting: {
            staffs: slack_.DEFAULT_MENTIONS,
            channels: slack_.DEFAULT_CHANNELS,
            message: slack_.DEFAULT_MESSAGE,
            staffModal: false,
        },
        interview: {
            staffs: slack_.INTERVIEW_STAFFS,
            channels: slack_.INTERVIEW_CHANNELS,
            message: slack_.INTERVIEW_MESSAGE,
            staffModal: false,
        },
    });
    const [voiceResponses, setVoiceResponses] = useState(defaultVoiceResponses);
    const [chatAPI, setChatAPI] = useState({
        serverEndpoint: process.env.REACT_APP_API,
        token: process.env.REACT_APP_API_TOKEN,
        kbotEndpoint: process.env.REACT_APP_KBOT_API,
    });
    const [gapi, setGapi] = useState({
        calendar: {
            clientId: process.env.REACT_APP_GAPI_CALENDAR_CLIENT_ID,
            key: process.env.REACT_APP_GAPI_CALENDAR_KEY,
        },
        textToSpeech: {
            key: process.env.REACT_APP_GAPI_TTS_KEY,
        },
    });
    const [welcomeText, setWelcomeText] = useState(defaultWelcomeText);
    const [receptionScreenVoice, setReceptionScreenVoice] = useState(
        defaultReceptionScreenVoice
    );
    const [supportedLanguages, setSupportedLanguages] = useState(
        Object.keys(languageCodes)
    );

    // OTHERS
    const [timezone, setTimezone] = useState(null);
    const [mainLanguage, setMainLanguage] = useState(null);

    const [password, setPassword] = useState(process.env.REACT_APP_PASSWORD);
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState(
        process.env.REACT_APP_PASSWORD
    );
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [firstLoad, setFirstLoad] = useState(true);

    // delete application setting(restore default)
    const [applicationDeletionDialogOpen, setApplicationDeletionDialogOpen] =
        useState(false);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const botId = useSelector((state) => state.chat.botId);

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null) {
            const current = settingsRef.current;
            // console.log(current);
            current.camera.on !== undefined && setIsCameraOn(current.camera.on);
            current.camera.show !== undefined &&
                setShowCamera(current.camera.show);
            current.camera.showFrame !== undefined &&
                setShowFaceFrame(current.camera.showFrame);
            current.camera.minDetectionArea !== null &&
                setMinDetectionArea(current.camera.minDetectionArea);
            current.microphone !== undefined &&
                setMicrophoneOn(current.microphone);
            current.localStorage !== undefined &&
                current.localStorage.maxCacheSize !== undefined &&
                setMaxCacheSize(current.localStorage.maxCacheSize);
            current.localStorage !== undefined &&
                current.localStorage.maxAudioAge !== undefined &&
                setMaxAudioAge(current.localStorage.maxAudioAge);
            current.receptionistVoice.on !== undefined &&
                setReceptionistVoiceEnabled(current.receptionistVoice.on);
            current.receptionistVoice.gender !== undefined &&
                setReceptionistVoiceGender(current.receptionistVoice.gender);
            current.receptionistVoice.endpoints !== null &&
                setReceptionistVoiceEndpoints(
                    current.receptionistVoice.endpoints
                );
            !!current.receptionScreenVoice &&
                setReceptionScreenVoice(current.receptionScreenVoice);
            current.door.on !== null && setDoorEnabled(current.door.on);
            current.door.server !== null && setDoorServer(current.door.server);
            current.faceRecognition.on !== null &&
                setFaceRecognitionEnabled(current.faceRecognition.on);
            current.faceRecognition.server !== null &&
                setFaceRecognitionServer(current.faceRecognition.server);
            current.slack?.token != null && setSlackToken(current.slack.token);
            current.slack?.config != null && setSlack(current.slack.config);
            !!current.welcomeText.guest &&
                !!current.welcomeText.staff &&
                setWelcomeText(current.welcomeText);
            current.supportedLanguages !== null &&
                current.supportedLanguages.length > 0 &&
                setSupportedLanguages(current.supportedLanguages);
            current.mainLanguage !== null &&
                setMainLanguage(current.mainLanguage);
            current.timezone !== null && setTimezone(current.timezone);
            current.password !== null && setPassword(current.password);
            current.password !== null && setConfirmPassword(current.password);
            current.gapi !== undefined && setGapi(current.gapi);
            current?.chatAPI?.serverEndpoint &&
                current?.chatAPI?.token &&
                current?.chatAPI?.kbotEndpoint &&
                setChatAPI(current.chatAPI);
            !!current.voiceResponses &&
                !!current.voiceResponses?.meeting?.meeting?.after?.known &&
                setVoiceResponses(current.voiceResponses);
            // console.log(current.supportedLanguages);
            // current[dbField].styles.color !== undefined && setCurrentColor(current[dbField].styles.color)
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };

    const setVoiceResponse = (path, value) => {
        path = path.join(".");
        const _voiceResponses = unFreezeObjOrArray(voiceResponses);
        updateObject(_voiceResponses, value, path);
        setVoiceResponses(_voiceResponses);
    };

    const setWelcomeText_ = (path, value) => {
        path = path.join(".");
        const _welcomeText = unFreezeObjOrArray(welcomeText);
        updateObject(_welcomeText, value, path);
        setWelcomeText(_welcomeText);
    };

    const setReceptionScreenVoice_ = (path, value) => {
        path = path.join(".");
        const _receptionScreenVoice = unFreezeObjOrArray(receptionScreenVoice);
        updateObject(_receptionScreenVoice, value, path);
        setReceptionScreenVoice(_receptionScreenVoice);
    };
    const setReceptionistVoiceEndpoints_ = (field, value, forceUpdate) => {
        if (value.length > 0) {
            setReceptionistVoiceEndpoints({
                ...receptionistVoiceEndpoints,
                [field]: value,
            });
        } else {
            const newReceptionistEndpoint = receptionistVoiceEndpoints;
            delete newReceptionistEndpoint[field];
            if (
                Object.keys(newReceptionistEndpoint).length === 0 &&
                newReceptionistEndpoint.constructor === Object
            )
                setReceptionistVoiceEndpoints(null);
            else setReceptionistVoiceEndpoints(newReceptionistEndpoint);
        }

        forceUpdate();
    };

    const setChatAPI_ = (field, value, forceUpdate) => {
        setChatAPI({
            ...chatAPI,
            [field]: value,
        });
        forceUpdate?.();
    };
    const setGapi_ = (field, subfield, value, forceUpdate) => {
        setGapi({
            ...gapi,
            [field]: {
                ...gapi[field],
                [subfield]: value,
            },
        });
        forceUpdate();
    };
    const setSlack_ = (field, subfield, value, forceUpdate) => {
        setSlack({
            ...slack,
            [field]: {
                ...slack[field],
                [subfield]: value,
            },
        });
        forceUpdate();
    };

    const setChatAPIInitialState = () => {
        const bot_token = bots.find((bot) => bot.bot_id === botId)?.bot_token;
        setChatAPI({
            serverEndpoint:
                `${process.env.REACT_APP_API.split("/")
                    .slice(0, -1)
                    .join("/")}/${botId}` || process.env.REACT_APP_API,
            token: bot_token || process.env.REACT_APP_API_TOKEN,
            kbotEndpoint: process.env.REACT_APP_KBOT_API,
        });
    };

    const getSlackConfig = () => {
        // remove unused properties from slack obj
        const slack_ = unFreezeObjOrArray(slack);
        Object.keys(slack_).map((key) => delete slack_[key].staffModal);
        return slack_;
    };

    const addStaff = (forceUpdate) => {
        const { field } = modalType;
        const subField = "staffs";
        const modal_ = "staffModal";
        setSlack({
            ...slack,
            [field]: {
                ...slack[field],
                [subField]: [
                    ...slack[field][subField],
                    { name: staffName, slack_id: staffSlackId },
                ],
                [modal_]: false,
            },
        });
        setStaffName("");
        setStaffSlackId("");
        forceUpdate();
    };

    const hasApplicationSettingsChanged = () => {
        const current = settingsRef.current;
        return (
            current.camera.on !== isCameraOn ||
            current.camera.show !== showCamera ||
            current.camera.showFrame !== showFaceFrame ||
            current.camera.minDetectionArea !== minDetectionArea ||
            current.microphone !== microphoneOn ||
            current.receptionistVoice.on !== receptionistVoiceEnabled ||
            current.receptionistVoice.gender !== receptionistVoiceGender ||
            !areObjectsSame(
                current.receptionistVoice.endpoints,
                receptionistVoiceEndpoints
            ) ||
            !areObjectsSame(
                current.receptionScreenVoice,
                receptionScreenVoice
            ) ||
            !areObjectsSame(current.gapi, gapi) ||
            !areObjectsSame(current.chatAPI, chatAPI) ||
            current.door.on !== doorEnabled ||
            current.door.server !== doorServer ||
            current.faceRecognition.on !== faceRecognitionEnabled ||
            current.faceRecognition.server !== faceRecognitionServer ||
            current.slack?.token !== slackToken ||
            !areObjectsSame(current.slack?.config, slack) ||
            !areObjectsSame(current.welcomeText, welcomeText) ||
            !areArraysSame(current.supportedLanguages, supportedLanguages) ||
            current.mainLanguage !== mainLanguage ||
            !areObjectsSame(current.timezone, timezone) ||
            current.password !== password ||
            current.localStorage.maxCacheSize !== maxCacheSize ||
            current.localStorage.maxAudioAge !== maxAudioAge ||
            !areObjectsSame(current.voiceResponses, voiceResponses)
        );
    };

    const validatePassword = useCallback(
        (showError = false) => {
            let error = { error: false, text: null };
            if (password !== confirmPassword)
                error = {
                    error: true,
                    text: translate("Passwords must be the same"),
                };
            if (password.trim().length <= 8)
                error = {
                    error: true,
                    text: translate("Length must be greater than 8"),
                };
            if (showError && error.error) {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: { type: "ERROR", message: error.text, size: "sm" },
                });
            }
            return error;
        },
        [password, confirmPassword, dispatch]
    );

    useEffect(() => {
        if (firstLoad && isModalOpen) {
            setChatAPIInitialState();
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
        // if(tabIndex.includes(tabRef.current) && isModalOpen){
        //     console.log(`reloading`);
        //     onLoad()
        // }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return {
        isCameraOn,
        setIsCameraOn,
        showCamera,
        setShowCamera,
        showFaceFrame,
        minDetectionArea,
        setMinDetectionArea,
        setShowFaceFrame,
        faceRecognitionEnabled,
        setFaceRecognitionEnabled,
        faceRecognitionServer,
        setFaceRecognitionServer,
        doorEnabled,
        setDoorEnabled,
        doorServer,
        setDoorServer,
        microphoneOn,
        setMicrophoneOn,
        receptionistVoiceEnabled,
        setReceptionistVoiceEnabled,
        receptionistVoiceGender,
        setReceptionistVoiceGender,
        receptionistVoiceEndpoints,
        setReceptionistVoiceEndpoints_,
        slackToken,
        setSlackToken,
        slack,
        setSlack_,
        getSlackConfig,
        welcomeText,
        setWelcomeText_,
        receptionScreenVoice,
        setReceptionScreenVoice_,
        supportedLanguages,
        setSupportedLanguages,
        staffName,
        setStaffName,
        staffSlackId,
        setStaffSlackId,
        modalType,
        setModalType,
        addStaff,
        timezone,
        setTimezone,
        mainLanguage,
        setMainLanguage,
        password,
        setPassword,
        showPassword,
        setShowPassword,
        confirmPassword,
        setConfirmPassword,
        showConfirmPassword,
        setShowConfirmPassword,
        applicationDeletionDialogOpen,
        setApplicationDeletionDialogOpen,
        hasApplicationSettingsChanged,
        setMaxAudioAge,
        maxAudioAge,
        maxCacheSize,
        setMaxCacheSize,
        setGapi_,
        gapi,
        setChatAPI_,
        chatAPI,
        validatePassword,
        voiceResponses,
        setVoiceResponse,
    };
};
