import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    try {
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return undefined;
            }
        }
    } catch (e) {
        return undefined;
    }
    return o;
};
const textSettingsFields = ["title", "companyName", "specialLabel"];
const customFields = ["background", "logo", ...textSettingsFields];
const excludeWhenNullFields = [
    "theme.primaryButtonTheme",
    "theme.secondaryButtonTheme",
    "theme.buttonTextTheme",
];
const uploadURL = "/api/v1/uploads/";

export const useAppearanceSettings = (fields) => {
    // APPEARANCE
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const appearanceSettings = useSelector(
        (state) => state.settings.appearanceSettings
    );
    const appearanceSettingsRef = useRef(appearanceSettings);
    appearanceSettingsRef.current = appearanceSettings;
    const [firstLoad, setFirstLoad] = useState(true);

    const [appearanceSettingsReturn, setAppearanceSettingsReturn] = useState(
        {}
    );

    const overrideDefaultAppearanceSettings = () => {
        let timer = null;
        if (appearanceSettingsRef.current !== null) {
            const newReturn = { ...appearanceSettingsReturn };
            for (const field of fields) {
                if (
                    excludeWhenNullFields.includes(field) &&
                    (Object.byString(
                        appearanceSettingsRef.current,
                        field
                    ) === undefined ||
                        Object.byString(
                            appearanceSettingsRef.current,
                            field
                        ) === null)
                )
                    continue;
                if (customFields.includes(field)) {
                    newReturn[field] = handleCustomFields(field);
                } else {
                    const newFieldName = field.replace(".", "_");
                    newReturn[newFieldName] =
                        Object.byString(
                            appearanceSettingsRef.current,
                            field
                        ) || null;
                }
            }
            setAppearanceSettingsReturn(newReturn);
        } else timer = setTimeout(overrideDefaultAppearanceSettings, 1000);
        return timer;
    };

    const handleCustomFields = (field) => {
        const appearanceSettings_ = appearanceSettingsRef.current;
        if (field === "background") {
            const background = appearanceSettings_.backgrounds.find(
                (background) =>
                    background.fileName ===
                    appearanceSettings_.selectedBackground
            );
            if (background !== undefined) {
                return {
                    backgroundImage: `url(${uploadURL}${background.fileId})`,
                };
            } else return {};
        }

        if (field === "logo") {
            const logo = appearanceSettings_.logo;
            if (logo !== undefined) {
                return `${uploadURL}${logo.fileId}`;
            } else return null;
        }

        if (textSettingsFields.includes(field)) {
            return getTextSettings(field, appearanceSettings_);
        }
    };

    const getTextSettings = (field, appearanceSettings_) => {
        const settings = {
            text: appearanceSettings_[field].text,
            styles: {},
        };
        const styles_ = appearanceSettings_[field].styles;
        isFieldValid("fontSize", styles_) &&
            (settings.styles.fontSize = `${styles_.fontSize}rem`);
        isFieldValid("fontFamily", styles_) &&
            (settings.styles.fontFamily = styles_.fontFamily);
        isFieldValid("color", styles_) &&
            (settings.styles.color = styles_.color);
        return settings;
    };

    const isFieldValid = (field, obj) =>
        obj[field] !== undefined && obj[field] !== null;

    useEffect(() => {
        if (updateSettings || firstLoad) {
            const timer = overrideDefaultAppearanceSettings();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return appearanceSettingsReturn;
};
