import { makeStyles } from "@material-ui/core/styles";
import sizes from "../../sizes";

const useStyles = makeStyles(({ primaryColor }) => ({
    root: {
        width: "100%",
        height: "13%",
        display: "flex",
        padding: "1em 1.5em",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
    },
    logo: {
        height: "5em",
        width: "18em",
        [sizes.down("lg")]: {
            width: "20em",
            height: "6em",
        },
        [sizes.down("md1")]: {
            width: "16em",
            height: "4.5em",
        },
        [sizes.down("sm")]: {
            width: "7.5em",
            height: "2.5em",
        },
    },
    header: {
        // border: '2px solid black',
        // width: "60%",
        // display: "flex",
        textAlign: "center",
        alignItems: "center",
        // justifyContent: 'center',
        [sizes.down("sm")]: {
            width: "100%",
        },
    },
    title: {
        // display: 'inline-flex',
        fontSize: "2.25rem",
        fontWeight: "bold",
        marginTop: "0.35em",
        color: primaryColor,
        // color: colors.primaryColor,
        [sizes.down("lg")]: {
            fontSize: "2.25rem",
        },
        [sizes.down("md1")]: {
            fontSize: "1.75rem",
        },
        [sizes.down("sm")]: {
            fontSize: "1.25rem",
        },
    },
    subTitle: {
        fontSize: "3.25rem",
    },
    langSelect: {
        // display: 'flex',
        // flexDirection: "row",
        // justifyContent: "center",
        // alignItems: 'center',
        // border: '2px solid black',
        paddingTop: "1em",
        [sizes.down("md1")]: {
            marginLeft: "1em",
        },
        [sizes.down("md")]:{
            fontSize: "0.75rem"
        }
    },
    flag: {
        width: "1em",
        marginLeft: "0.25em",
    },
    timer: {
        marginTop: "0.5em",
        display: "block",
        fontSize: "1.15em",
    },
    timer2: {
        display: "block",
        fontSize: "1.15em",
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
}));

export default useStyles;
