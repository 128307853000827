import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export const useTextSettings = (
    defaultTitle_,
    defaultFontSize_,
    defaultFontFamily_,
    defaultColor_,
    dbField,
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen
) => {
    const [text, setText] = useState(defaultTitle_);
    const [fontSize, setFontSize] = useState(defaultFontSize_); // put default value
    const [fontFamily, setFontFamily] = useState(defaultFontFamily_); // put default value
    const [currentColor, setCurrentColor] = useState(defaultColor_); // put default value
    const [firstLoad, setFirstLoad] = useState(true);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null ) {
            const current = settingsRef.current;
            current[dbField].text.length > 0 && setText(current[dbField].text);
            current[dbField].styles.fontSize !== undefined &&
                setFontSize(current[dbField].styles.fontSize);
            if (current[dbField].styles.fontFamily !== undefined) {
                let fontFamily_ = new RegExp("(?<=')(.*)(?=',)", "g").exec(
                    current[dbField].styles.fontFamily
                );
                setFontFamily(
                    fontFamily_ !== null ? fontFamily_[0] : fontFamily
                );
            }
            current[dbField].styles.color !== undefined &&
                setCurrentColor(current[dbField].styles.color);
            // current[dbField].styles.color !== undefined && setCurrentColor(current[dbField].styles.color)
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };
    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return useMemo(
        () => ({
            text,
            setText,
            fontSize,
            setFontSize,
            fontFamily,
            setFontFamily,
            currentColor,
            setCurrentColor,
        }),
        [text, fontFamily, fontSize, currentColor]
    );
};
