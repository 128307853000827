import React, { memo, useCallback, useEffect, useState } from "react";
import userStyles from "../../../styles/UI/modals/FaceRegistrationModalStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { translate } from "../../../utils/i18n";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { createUser, updateUser } from "../../../store/actions/userActions";
import { addToNotifications } from "../../../store/actions/notificationActions";
import {
    Collapse,
    FormControl,
    IconButton,
    InputAdornment,
    MenuItem,
    Tooltip,
    Typography,
} from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import EventIcon from "@material-ui/icons/Event";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import DateFnsUtils from "@date-io/date-fns";
import ConfirmationModal from "./ConfirmationModal";
import { Transition } from "../settings/Settings";

const initFormError = (form) => {
    const keys = Object.keys(form);
    const _formError = {};
    keys.forEach((key) => {
        _formError[key] = { error: false, text: null };
    });
    return _formError;
};

const FaceRegistrationModal = (props) => {
    const classes = userStyles();
    const { open, handleClose, history, role: role_, canSetRole } = props;
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        firstName: "",
        lastName: "",
        displayName: "",
        dob: null,
        email: "",
        slackId: "",
        role: role_,
    });
    const [formError, setFormError] = useState(initFormError(form));
    const userInfo = useSelector((state) => state.user.userInfo);
    const userFaceId = useSelector((state) => state.user.userFaceId);
    const [expanded, setExpanded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [
        isUpdateConfirmationDialogOpen,
        setIsUpdateConfirmationDialogOpen,
    ] = useState(false);

    const setFormValue = (field) => (event) => {
        const required = ["firstName", "lastName", "slackId"];
        if (required.includes(field)) {
            resetFormError(field);
        }

        setForm({
            ...form,
            [field]: event.target.value,
        });
    };

    const setFormValueDate = (field) => (date) => {
        setForm({
            ...form,
            [field]: date,
        });
    };

    const resetFormError = (field) => {
        setFormError({
            ...formError,
            [field]: { error: false, text: null },
        });
    };

    const resetForm = () => {
        setForm({
            firstName: "",
            lastName: "",
            displayName: "",
            dob: null,
            email: "",
            slackId: "",
            role: role_,
        });
        setExpanded(false);
        setIsLoading(false);
    };

    const handleUpdate = useCallback(async () => {
        setIsLoading(true);
        const _form = {
            ...form,
            email:
                form.email === null || form.email?.length === 0
                    ? null
                    : form.email,
            slackId:
                form.slackId === null || form.slackId?.length === 0
                    ? null
                    : form.slackId,
        };
        if (_form.displayName.length === 0) {
            _form.displayName = `${_form.firstName} ${_form.lastName}`;
        }
        if (userInfo?._id) {
            const user = await dispatch(updateUser(userInfo._id, _form));
            if (user) {
                dispatch(
                    addToNotifications({
                        message: translate("User info updated!"),
                        type: "SUCCESS",
                        size: "md",
                    })
                );
                resetForm();
            }
        } else {
            const user = await dispatch(
                createUser({
                    ..._form,
                    faceId: userFaceId,
                    hasUserRegisteredForFace: true,
                })
            );
            if (user) {
                dispatch(
                    addToNotifications({
                        message: translate("User info created!"),
                        type: "SUCCESS",
                        size: "md",
                    })
                );
                resetForm();
            }
        }
        setIsLoading(false);
        setIsUpdateConfirmationDialogOpen(false);
        handleClose();
    }, [form, userFaceId, userInfo]);

    const handleSubmit = useCallback(() => {
        handleClose();
        const _form = {
            ...form,
            email:
                form.email === null || form.email?.length === 0
                    ? null
                    : form.email,
            slackId:
                form.slackId === null || form.slackId?.length === 0
                    ? null
                    : form.slackId,
        };
        if (_form.displayName.length === 0) {
            _form.displayName = `${_form.firstName} ${_form.lastName}`;
        }
        resetForm();
        history.push({
            pathname: "/face-registration",
            state: _form,
        });
    }, [form]);

    const handleError = useCallback(
        (type, values) => {
            switch (type) {
                case "email":
                    if (
                        values?.length > 0 &&
                        !values.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
                    )
                        return {
                            error: true,
                            text: translate("Field must be a valid mail"),
                        };
                    return { error: false, text: null };
                case "required":
                    let error = false;
                    const errors = {};
                    values.map((value) => {
                        if (form[value] === null || form[value].length === 0) {
                            error = true;
                            errors[value] = {
                                error: true,
                                text: translate("Field is required"),
                            };
                        } else errors[value] = { error: false, text: null };
                    });
                    setFormError({
                        ...formError,
                        ...errors,
                    });
                    return error;
                default:
                    return { error: false, text: null };
            }
        },
        [form, formError]
    );

    const validateForm = useCallback(() => {
        const { email, role } = form;
        const required = ["firstName", "lastName"];
        if (role === "staff") required.push("slackId");
        const errors = [
            handleError("required", required),
            handleError("email", email).error,
        ];
        if (errors.includes(true)) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate(
                        "Please resolve all the errors in the form"
                    ),
                    size: "md",
                })
            );
            return false;
        } else return true;
    }, [form, handleError]);

    const handleUpdateOrRegister = useCallback(() => {
        if (validateForm()) {
            if (userFaceId) {
                setIsUpdateConfirmationDialogOpen(true);
            } else {
                handleSubmit();
            }
        }
    }, [userFaceId, validateForm, handleSubmit]);

    useEffect(() => {
        if (open) {
            if (
                userFaceId &&
                Object.keys(userInfo).length !== 0 &&
                userInfo.constructor === Object
            )
                // if user is recognized but there is info in db. Update fields
                setForm(userInfo);
            else resetForm();
        }
    }, [open]);

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("Staff Information")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate(
                            "Enter your staff information for face registration"
                        )}
                    </DialogContentText>
                    <FormGroup className={classes.formContainer}>
                        <FormControl className={classes.formControl}>
                            <Grid container justify="space-between">
                                <TextField
                                    label={translate("First Name")}
                                    placeholder={translate(
                                        "Enter your first name"
                                    )}
                                    required
                                    value={form.firstName}
                                    onChange={setFormValue("firstName")}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={formError.firstName.error}
                                    helperText={formError.firstName.text}
                                />
                                <TextField
                                    label={translate("Last Name")}
                                    placeholder={translate(
                                        "Enter your last name"
                                    )}
                                    required
                                    value={form.lastName}
                                    onChange={setFormValue("lastName")}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={formError.lastName.error}
                                    helperText={formError.lastName.text}
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justify="space-between">
                                <TextField
                                    label={translate("Display Name")}
                                    placeholder={translate(
                                        "Enter your display name"
                                    )}
                                    value={form.displayName}
                                    onChange={setFormValue("displayName")}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                />
                                <TextField
                                    label={translate("Email")}
                                    placeholder={translate("Enter your email")}
                                    value={form.email || ""}
                                    onChange={setFormValue("email")}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={
                                        handleError("email", form["email"])
                                            .error
                                    }
                                    helperText={
                                        handleError("email", form["email"]).text
                                    }
                                />
                            </Grid>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Grid container justify="space-between">
                                <TextField
                                    id="outlined-select-role"
                                    select
                                    required
                                    disabled={!canSetRole}
                                    className={classes.formItemGrid}
                                    label={translate("Role")}
                                    value={form.role || ""}
                                    onChange={setFormValue("role")}
                                    helperText={translate(
                                        "Please select your role"
                                    )}
                                    variant="outlined"
                                >
                                    <MenuItem value={"guest"}>
                                        {translate("Guest")}
                                    </MenuItem>
                                    <MenuItem value={"staff"}>
                                        {translate("Staff")}
                                    </MenuItem>
                                </TextField>
                                <TextField
                                    label={translate("Slack Id")}
                                    placeholder={translate(
                                        "Enter your slack Id"
                                    )}
                                    required={form.role !== "guest"}
                                    disabled={form.role === "guest"}
                                    value={form.slackId || ""}
                                    onChange={setFormValue("slackId")}
                                    variant="outlined"
                                    className={classes.formItemGrid}
                                    error={
                                        form.role === "staff"
                                            ? formError.slackId.error
                                            : false
                                    }
                                    helperText={
                                        form.role === "staff"
                                            ? formError.slackId.text
                                            : ""
                                    }
                                />
                            </Grid>
                        </FormControl>
                        <FormControl
                            className={classnames(
                                classes.formControl,
                                classes.singleFormItem
                            )}
                        >
                            <DatePicker
                                autoOk
                                disableFuture
                                inputVariant="outlined"
                                label={translate("Date of birth")}
                                format="MM/dd/yyyy"
                                value={form.dob}
                                maxDate={new Date("2005-01-01")}
                                allowKeyboardControl={false}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <EventIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                onChange={setFormValueDate("dob")}
                            />
                        </FormControl>
                    </FormGroup>
                    <div className={classes.expandContainer}>
                        <Tooltip
                            title={translate(expanded ? "Collapse" : "Expand")}
                            arrow={true}
                            placement={"bottom"}
                        >
                            <IconButton
                                className={classnames(classes.expand, {
                                    [classes.expandOpen]: expanded,
                                })}
                                onClick={() => setExpanded((val) => !val)}
                                aria-expanded={expanded}
                                aria-label="show more"
                            >
                                <ExpandMoreIcon fontSize={"large"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <FormControl
                            style={{ width: "70%", marginLeft: "1em" }}
                        >
                            {form._id && (
                                <Grid
                                    container
                                    justify="space-between"
                                    className={classes.gridItem}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {translate("UserId")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {form._id}
                                    </Typography>
                                </Grid>
                            )}
                            {form.voiceId !== undefined && (
                                <Grid
                                    container
                                    justify="space-between"
                                    className={classes.gridItem}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {translate("VoiceId")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {form.voiceId
                                            ? form.voiceId
                                            : translate("No Id")}
                                    </Typography>
                                </Grid>
                            )}
                            {(userFaceId || form.faceId !== undefined) && (
                                <Grid
                                    container
                                    justify="space-between"
                                    className={classes.gridItem}
                                >
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {translate("FaceId")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        component="p"
                                    >
                                        {form.faceId
                                            ? form.faceId
                                            : userFaceId
                                            ? userFaceId
                                            : translate("No Id")}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid
                                container
                                justify="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("Voice")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    <Tooltip
                                        title={translate(
                                            form.hasUserRegisteredForVoice
                                                ? "Registered"
                                                : "Unregistered"
                                        )}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        {form.hasUserRegisteredForVoice ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </Tooltip>
                                </Typography>
                            </Grid>
                            <Grid
                                container
                                justify="space-between"
                                className={classes.gridItem}
                            >
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    {translate("Face")}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    color="textSecondary"
                                    component="p"
                                >
                                    <Tooltip
                                        title={translate(
                                            form.hasUserRegisteredForFace ||
                                                userFaceId
                                                ? "Registered"
                                                : "Unregistered"
                                        )}
                                        arrow={true}
                                        placement={"bottom"}
                                    >
                                        {form.hasUserRegisteredForFace ||
                                        userFaceId ? (
                                            <CheckCircleIcon />
                                        ) : (
                                            <CancelIcon />
                                        )}
                                    </Tooltip>
                                </Typography>
                            </Grid>
                        </FormControl>
                    </Collapse>
                    <DialogActions className={classes.button}>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            disabled={isLoading}
                        >
                            {translate("Abort")}
                        </Button>
                        <Button
                            onClick={handleUpdateOrRegister}
                            color="primary"
                            variant={"contained"}
                            disabled={isLoading}
                        >
                            {translate(userFaceId ? "Update" : "Register")}
                        </Button>
                    </DialogActions>
                    <ConfirmationModal
                        open={isUpdateConfirmationDialogOpen}
                        handleClose={() =>
                            setIsUpdateConfirmationDialogOpen(false)
                        }
                        handleSuccess={handleUpdate}
                        title={translate("Update Your Info")}
                        message={translate("Are you sure you want to update?")}
                        abortText={translate("No")}
                        successText={translate("Yes")}
                        isLoading={isLoading}
                    />
                </DialogContent>
            </Dialog>
        </MuiPickersUtilsProvider>
    );
};

FaceRegistrationModal.propTypes = {
    history: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    role: PropTypes.oneOf(["guest", "staff"]),
    canSetRole: PropTypes.bool,
};

FaceRegistrationModal.defaultProps = {
    role: "staff",
    canSetRole: true,
};

export default memo(FaceRegistrationModal);
