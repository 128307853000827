import React, { Suspense, useEffect, useState } from "react";
import { Row, Spin } from "antd";
import HelpSelection from "../UI/HelpSelection";
import Dialog from "@material-ui/core/Dialog";
import { Transition } from "../UI/settings/Settings";
import DialogContent from "@material-ui/core/DialogContent";
import { SuspenseImg as SuspenseImgClone } from "../UI/SuspenseImg";
import useStyles from "../../styles/pages/MapPageStyles";
import { useLocation } from "react-router-dom";
import { useAppearanceSettings } from "../../hooks/settings/useAppearanceSettings";
import { translate } from "../../utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import { languageCodes_ } from "../../styles/constants";
import { speechSynthesisChunker } from "../../store/actions/chatActions";

const uploadURL = "/api/v1/uploads/";

const MapPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const language = useSelector((state) => state.language.currentLanguage);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const { isUserRecognized, userInfo } = useSelector((state) => state.user);
    // const history = useHistory();
    const { map } = useAppearanceSettings(["map"]);
    const [open, setOpen] = useState(false);
    const [currentMap, setCurrentMap] = useState(null);

    const toggleMap = (map) => {
        setOpen(true);
        setCurrentMap(map);
        let voiceResponse = map.voiceResponse?.unknown?.[language];
        if (isUserRecognized)
            voiceResponse = map.voiceResponse?.known?.[language]?.replace(
                "<name>",
                userInfo.displayName
            );
        dispatch(speechSynthesisChunker(voiceResponse, language, isGapiReady));
    };

    const handleClose = () => {
        setOpen(false);
        // if (location?.state?.buttonName){
        //     history.push('/reception')
        // }
    };

    useEffect(() => {
        if (map?.maps?.length > 0) {
            const { buttonName } = location.state || {};
            if (!buttonName) return;
            const selectedMap = map.maps.find(
                (map) => map.buttonName === buttonName
            );
            if (selectedMap) {
                toggleMap(selectedMap);
            }else{
                let voiceResponse = map?.onFindMapFail?.unknown?.[language];
                if (isUserRecognized)
                    voiceResponse = map?.onFindMapFail?.known?.[language]?.replace(
                        "<name>",
                        userInfo.displayName
                    );
                dispatch(speechSynthesisChunker(voiceResponse, language, isGapiReady));
            }
        }
    }, [map, location.state]);

    return (
        <Row
            style={{
                width: "100%",
            }}
            justify="center"
            align="middle"
        >
            {map?.maps?.length > 0 && (
                <HelpSelection
                    options={map.maps.map((map) => ({
                        text: translate(
                            map?.buttonLabel?.[languageCodes_[language]]
                        ),
                        onClick: () => toggleMap(map),
                    }))}
                    style={{ width: "100%" }}
                />
            )}
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={handleClose}
                maxWidth={"md"}
                fullWidth={true}
            >
                <DialogContent className={classes.modalContent}>
                    <Suspense fallback={<Spin />}>
                        <SuspenseImgClone
                            src={`${uploadURL}${currentMap?.map?.fileId}`}
                            alt={"A map"}
                            className={classes.mapImg}
                        />
                    </Suspense>
                </DialogContent>
            </Dialog>
        </Row>
    );
};

export default MapPage;
