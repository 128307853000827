import axios from "axios";
import { ADD_NOTIFICATION } from "./types";
import { translate } from "../../utils/i18n";
import ExcelJS from "exceljs";
import moment from "moment-timezone";

export const checkInUser = (userId) => async (dispatch) =>
    await dispatch(createTracker({ userId, type: "checkIn" }));

export const checkOutUser = (userId) => async (dispatch) =>
    await dispatch(createTracker({ userId, type: "checkOut" }));

const createTracker = (tracker) => async (dispatch) => {
    await axios
        .post("/api/v1/trackers", tracker)
        .then((res) => {
            if (res.data.success) {
                if (tracker.type === "checkOut")
                    dispatch({
                        type: ADD_NOTIFICATION,
                        payload: {
                            type: "SUCCESS",
                            message: translate("Checked out successfully!"),
                            size: "md",
                        },
                    });
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

const getTrackers = (queryString = "") => async (dispatch) => {
    return await axios
        .get(`/api/v1/trackers?${queryString}`)
        .then((res) => {
            if (res.data.success) {
                return res.data.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const getTrackersByUserId = (userId, queryString = "") => async (
    dispatch
) => {
    return await axios
        .get(`/api/v1/trackers/user/${userId}?${queryString}`)
        .then((res) => {
            if (res.data.success) {
                return res.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};
export const getTrackersByUsersExcel = (queryString) => async (dispatch) => {
    return await axios
        .get(`/api/v1/trackers/users/excel?${queryString}`, {
            responseType: "arraybuffer",
        })
        .then((res) => {
            if (res.data.success === undefined) {
                return res;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const updateTracker = (trackerId, updatedTracker) => async (
    dispatch
) => {
    return await axios
        .put(`/api/v1/trackers/${trackerId}`, updatedTracker)
        .then((res) => {
            if (res.data.success) {
                return res.data.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const deleteTracker = (trackerId) => async (dispatch) => {
    return await axios
        .delete(`/api/v1/trackers/${trackerId}`)
        .then((res) => {
            if (!res.data.success) {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            }

            return res.data.success;
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const deleteTrackersByUserId = (trackerId) => async (dispatch) => {
    return await axios
        .delete(`/api/v1/trackers/user/${trackerId}`)
        .then((res) => {
            if (!res.data.success) {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            }

            return res.data.success;
        })
        .catch((err) => {
            console.log(err.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

const getDate = (trackers, type, textOnInvalidTime) => {
    const trackersFlattened = trackers.map((tracker) => moment(tracker.time));
    switch (type) {
        case "min":
            const min =
                trackersFlattened.length > 0
                    ? moment.min(trackersFlattened)
                    : [];
            return (
                trackers.find((tracker) => moment(tracker.time).isSame(min)) ||
                translate(`No ${textOnInvalidTime}`)
            );
        case "max":
            const max =
                trackersFlattened.length > 0
                    ? moment.max(trackersFlattened)
                    : [];
            return (
                trackers.find((tracker) => moment(tracker.time).isSame(max)) ||
                translate(`No ${textOnInvalidTime}`)
            );
        default:
            return "";
    }
};

const downloadExcelFile = (workbook, fileName) => {
    workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = fileName;
        anchor.click();
        window.URL.revokeObjectURL(url);
    });
};

export const exportTrackersToExcel = (trackerGroups, user) => {
    let { _id, firstName, lastName, displayName, slackId, email, dob } = user;
    displayName = removeAccents(displayName);
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(displayName);

    const rowsForTackers = trackerGroups.map((trackers) => {
        const { trackers: trackerGroup, day: date } = trackers;
        const checkInGroup = trackerGroup.filter(
            (tracker) => tracker.type === "checkIn"
        );
        const checkOutGroup = trackerGroup.filter(
            (tracker) => tracker.type === "checkOut"
        );
        const firstCheckIn = getDate(checkInGroup, "min", "Check In");
        const lastCheckOut = getDate(checkOutGroup, "max", "Check Out");
        let totalWorkHours;
        if (firstCheckIn.time && lastCheckOut.time) {
            const _firstCheckIn = moment(firstCheckIn.time);
            const _lastCheckOut = moment(lastCheckOut.time);
            totalWorkHours = _lastCheckOut
                .diff(_firstCheckIn, "hours", true)
                .toFixed(2);
        } else totalWorkHours = "No total work hours";
        return [
            moment(date).format("lll"),
            firstCheckIn.time
                ? moment(firstCheckIn.time).format("LT")
                : firstCheckIn,
            lastCheckOut.time
                ? moment(lastCheckOut.time).format("LT")
                : lastCheckOut,
            totalWorkHours,
        ];
    });
    /*TITLE*/
    sheet.mergeCells("A1", "G2");
    sheet.getCell("C1").value = "User Info";
    sheet.getCell("C1").font = { size: 16, bold: true };
    sheet.getCell("C1").alignment = {
        vertical: "middle",
        horizontal: "center",
    };

    /* USER INFO TABLE */
    sheet.addTable({
        name: `UserInfo`,
        ref: "A3",
        headerRow: true,
        totalsRow: false,
        columns: [
            { name: "Id", width: 100 },
            { name: "First Name" },
            { name: "Last Name" },
            { name: "Display Name" },
            { name: "Email" },
            { name: "Date of Birth" },
            { name: "Slack Id" },
        ],
        rows: [
            [
                _id,
                firstName,
                lastName,
                displayName,
                email,
                moment(dob).format("LL"),
                slackId,
            ],
        ],
    });

    /*TITLE*/
    sheet.mergeCells("A6", "D6");
    sheet.getCell("B6").value = "Trackers";
    sheet.getCell("B6").font = { size: 16, bold: true };
    sheet.getCell("B6").alignment = {
        vertical: "middle",
        horizontal: "center",
    };

    // TRACKER TABLE
    if (rowsForTackers.length > 0) {
        sheet.addTable({
            name: `Trackers`,
            ref: "A7",
            headerRow: true,
            totalsRow: false,
            columns: [
                { name: "Date" },
                { name: "First Check In" },
                { name: "Last Check Out" },
                { name: "Total work hours" },
            ],
            rows: rowsForTackers,
        });
    }

    /*Auto column width*/
    for (let i = 0; i < sheet.columns.length; i += 1) {
        let dataMax = 0;
        const column = sheet.columns[i];
        for (let j = 1; j < column.values.length; j += 1) {
            const columnLength = column.values[j] ? column.values[j].length : 0;
            if (columnLength > dataMax) {
                dataMax = columnLength;
            }
        }
        column.width = dataMax < 10 ? 10 : dataMax;
    }
    downloadExcelFile(workbook, `${displayName}.xlsx`);
};

export const downloadFileWithDepositionAttachment = (res) => {
    const contentDisposition = res.headers["content-disposition"];
    const contentType = res.headers["content-type"];
    const fileName = contentDisposition.match(/filename=(.+)/)[1];
    const blob = new Blob([res.data], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    window.URL.revokeObjectURL(url);
};

const removeAccents = (str) =>
    str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
