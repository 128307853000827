import React, {useState, Suspense, useMemo} from "react";
import userStyles from "../../../styles/UI/settings/TextSettingsStyles";
import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import CloseIcon from "@material-ui/icons/Close";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { translate } from "../../../utils/i18n";
import PropTypes from "prop-types";
import classnames from "classnames";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { a11yProps, TabPanel } from "./Settings";
import { languageCodes } from "../../../styles/constants";
import { useSelector } from "react-redux";
import known from "../../../images/face_registration2.png";
import unknown from "../../../images/unkown.png";
import { Spin } from "antd";

export const deepValue = (o, p) => {
    if (!p) return o;
    return p.split(".").reduce((a, v) => a[v], o);
};

export const VoiceSettingsPopperSkeleton = () => {
    return (
        <div
            id={"chatContent"}
            style={{
                height: "20em",
                width: "20em",
                overflowY: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Spin />
        </div>
    );
};

const VoiceSettingsPopper = ({
    settings,
    setSettings,
    supportedLanguages,
    fields = [],
    defaultVoiceResponses,
    buttonStyle,
    header,
    tabTitles,
    tabFields = ["known", "unknown"],
}) => {
    const classes = userStyles();
    const language = useSelector((state) => state.language.currentLanguage);
    const [tabValue, setTabValue] = useState(0);
    const [toggleList, setToggleList] = useState(false);
    const open = Boolean(toggleList);
    const id = open ? "simple-popover" : undefined;

    const propIsValid = (prop) => typeof prop != "undefined";
    const handleClose = () => setToggleList(null);

    const buttonText =  useMemo(()=>{
        const known = deepValue(settings, fields.join("."))?.[tabFields[0]][
            language
            ]
        const unknown = deepValue(settings, fields.join("."))?.[tabFields[1]][
            language
            ]
        return known || unknown
    }, [settings, fields, tabFields, language])

    return (
        <>
            <div id={"overrideButtonText"} className={classes.buttonContainer}>
                <Button
                    aria-describedby={id}
                    onClick={(e) => setToggleList(e.currentTarget)}
                    className={classnames(classes.text, buttonStyle)}
                >
                    {buttonText}
                </Button>
            </div>
            <Popover
                id={id}
                className={"overrideButtonText"}
                open={open}
                anchorEl={toggleList}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <Suspense fallback={<VoiceSettingsPopperSkeleton />}>
                    <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader
                                component="div"
                                id="nested-list-subheader"
                                className={classes.listSubHeader}
                            >
                                {propIsValid(header)
                                    ? header
                                    : translate("Voice Settings")}
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </ListSubheader>
                        }
                        className={classes.list}
                    >
                        <Tabs
                            value={tabValue}
                            onChange={(e, newTabValue) =>
                                setTabValue(newTabValue)
                            }
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                            aria-label="scrollable auto tabs example"
                        >
                            <Tab
                                label={tabTitles?.[0] || translate("Known")}
                                icon={
                                    <img
                                        src={known}
                                        alt="known"
                                        className={classes.icon}
                                    />
                                }
                                {...a11yProps(0)}
                            />
                            <Tab
                                label={tabTitles?.[1] || translate("Unknown")}
                                icon={
                                    <img
                                        src={unknown}
                                        alt="unknown"
                                        className={classes.icon}
                                    />
                                }
                                {...a11yProps(1)}
                            />
                        </Tabs>

                        <TabPanel
                            value={tabValue}
                            index={0}
                            id={"chatContent"}
                            className={classes.tabPanel}
                        >
                            {supportedLanguages.map((langCode, index) => (
                                <div key={index}>
                                    <ListItem className={classes.listItem2}>
                                        <ListItemText
                                            primary={translate(
                                                langCode
                                            ).capitalize()}
                                        />
                                        <TextField
                                            className={classes.currentText}
                                            id="standard-textarea"
                                            value={
                                                deepValue(
                                                    settings,
                                                    fields.join(".")
                                                )[tabFields[0]][
                                                    languageCodes[langCode]
                                                ]
                                            }
                                            placeholder={
                                                !!defaultVoiceResponses
                                                    ? deepValue(
                                                          defaultVoiceResponses,
                                                          fields.join(".")
                                                      )[tabFields[0]][
                                                          languageCodes[
                                                              langCode
                                                          ]
                                                      ]
                                                    : ""
                                            }
                                            multiline
                                            onChange={(e) =>
                                                setSettings(
                                                    [
                                                        ...fields,
                                                        tabFields[0],
                                                        languageCodes[langCode],
                                                    ],
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </ListItem>
                                </div>
                            ))}
                        </TabPanel>
                        <TabPanel
                            value={tabValue}
                            index={1}
                            id={"chatContent"}
                            className={classes.tabPanel}
                        >
                            {supportedLanguages.map((langCode, index) => (
                                <div key={index}>
                                    <ListItem className={classes.listItem2}>
                                        <ListItemText
                                            primary={translate(
                                                langCode
                                            ).capitalize()}
                                        />
                                        <TextField
                                            className={classes.currentText}
                                            id="standard-textarea"
                                            value={
                                                deepValue(
                                                    settings,
                                                    fields.join(".")
                                                )[tabFields[1]][
                                                    languageCodes[langCode]
                                                ]
                                            }
                                            placeholder={
                                                !!defaultVoiceResponses
                                                    ? deepValue(
                                                          defaultVoiceResponses,
                                                          fields.join(".")
                                                      )[tabFields[1]][
                                                          languageCodes[
                                                              langCode
                                                          ]
                                                      ]
                                                    : ""
                                            }
                                            multiline
                                            onChange={(e) =>
                                                setSettings(
                                                    [
                                                        ...fields,
                                                        tabFields[1],
                                                        languageCodes[langCode],
                                                    ],
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </ListItem>
                                </div>
                            ))}
                        </TabPanel>
                    </List>
                </Suspense>
            </Popover>
        </>
    );
};

VoiceSettingsPopper.propTypes = {
    settings: PropTypes.object.isRequired,
    setSettings: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    supportedLanguages: PropTypes.array.isRequired,
    defaultVoiceResponses: PropTypes.object,
    defaultVoiceResponsesFields: PropTypes.array,
    tabTitles: PropTypes.array,
    tabFields: PropTypes.array,
};

export default VoiceSettingsPopper;
