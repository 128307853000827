import {
    SET_CURRENT_USER,
    CLEAR_USER,
    CLEAR_USER_FACE_ID,
    DECREASE_RECOGNIZED_COUNT,
    INCREASE_RECOGNIZED_COUNT,
    SET_CAN_USE_RECOGNITION_API,
    RESET_RECOGNIZED_COUNT,
    SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED,
    SET_CURRENT_USER_INFO,
} from "../actions/types";
import { areArraysSame } from "../actions/chatActions";

const initialState = {
    isUserRecognized: false,
    userFaceId: null,
    userInfo: {},
    currentUsers: [],
    recognizedCount: 0,
    hasPreviousCurrentUsersChanged: false,
    canUseRecognitionAPI: true,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_CURRENT_USER:
            if (
                areArraysSame(state.currentUsers, [
                    ...new Set([...state.currentUsers, action.userFaceId]),
                ])
            )
                return {
                    ...state,
                    isUserRecognized: true,
                    userFaceId: action.userFaceId,
                };
            else {
                return {
                    ...state,
                    isUserRecognized: true,
                    userFaceId: action.userFaceId,
                    currentUsers: [
                        ...new Set([...state.currentUsers, action.userFaceId]),
                    ],
                    hasPreviousCurrentUsersChanged: true,
                };
            }
        case CLEAR_USER:
            return {
                ...state,
                isUserRecognized: false,
                userFaceId: null,
                userInfo: {},
                currentUsers:
                    state.recognizedCount !== 0 ? state.currentUsers : [],
                // recognizedCount: 0
            };
        case CLEAR_USER_FACE_ID:
            return {
                ...state,
                userFaceId: null,
            };
        case INCREASE_RECOGNIZED_COUNT:
            return {
                ...state,
                recognizedCount:
                    state.recognizedCount !== 10
                        ? state.recognizedCount + 1
                        : state.recognizedCount,
            };
        case DECREASE_RECOGNIZED_COUNT:
            return {
                ...state,
                recognizedCount:
                    state.recognizedCount === 0 ? 0 : state.recognizedCount - 1,
            };
        case RESET_RECOGNIZED_COUNT:
            return {
                ...state,
                recognizedCount: 0,
            };
        case SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED:
            return {
                ...state,
                hasPreviousCurrentUsersChanged:
                    action.hasPreviousCurrentUsersChanged,
            };
        case SET_CAN_USE_RECOGNITION_API:
            return {
                ...state,
                canUseRecognitionAPI: action.canUseRecognitionAPI,
            };
        case SET_CURRENT_USER_INFO:
            return {
                ...state,
                userInfo: action.userInfo,
            };
        default:
            return state;
    }
}
