import { makeStyles } from "@material-ui/core/styles";
import background from "../../../images/background.jpg";
import {
    cardHeader_,
    hexToRgb,
    whiteColor,
} from "../../material-dashboard-react";
import chroma from "chroma-js";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    root: {
        // border: '2px solid black',
        backgroundImage: `url(${background})`,
        width: "100%",
        height: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        color: `rgba(${hexToRgb(secondaryColor)}, 0.65)`,
    },
    container: {
        display: "flex",
        width: "100%",
        height: "100%",
    },
    containerWithFooter: {
        height: "87% !important",
    },
    "@global .MuiSlider-thumb.MuiSlider-thumbColorPrimary.PrivateValueLabel-thumb-84, .MuiSlider-root": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiInput-underline:after": {
        borderBottomColor: `${primaryColor} !important`,
    },
    "@global .MuiTab-textColorPrimary.Mui-selected": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiTabs-indicator": {
        backgroundColor: `${primaryColor} !important`,
    },

    "@global .MuiSwitch-colorPrimary.Mui-checked": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
        backgroundColor: `${primaryColor} !important`,
    },
    "@global .MuiChip-root.MuiAutocomplete-tag.MuiAutocomplete-tagSizeSmall.MuiChip-outlined.MuiChip-deletable": {
        color: `${whiteColor} !important`,
        borderColor: "transparent !important",
        marginBottom: "1em !important",
        marginTop: "1em !important",
        ...cardHeader_(primaryColor),
    },
    "@global .MuiIconButton-colorPrimary": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiIconButton-colorPrimary:hover": {
        backgroundColor: `rgba(${hexToRgb(primaryColor)}, 0.04) !important`,
    },
    "@global .MuiChip-root.MuiAutocomplete-tag.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-deletable": {
        color: `${whiteColor} !important`,
        borderColor: "transparent !important",
        marginBottom: "1em !important",
        marginTop: "1em !important",
        ...cardHeader_(primaryColor),
    },
    "@global .MuiChip-root.MuiChip-outlined.MuiChip-sizeSmall.MuiChip-deletable": {
        color: `${whiteColor} !important`,
        borderColor: "transparent !important",
        marginBottom: "1em !important",
        marginTop: "1em !important",
        ...cardHeader_(primaryColor),
    },
    "@global label.Mui-focused": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: `${primaryColor} !important`,
    },
    "@global .MuiTypography-root.MuiTypography-h6": {
        color: primaryColor,
    },
    "@global .MuiButton-containedPrimary": {
        backgroundColor: primaryColor,
        color: "#fff !important",
    },
    "@global .MuiButton-containedPrimary:hover": {
        backgroundColor: `${chroma(primaryColor).darken()}`,
        // backgroundColor: "#096dd9"
    },
    "@global .MuiButton-outlinedPrimary": {
        color: primaryColor,
        border: `1px solid rgba(${hexToRgb(primaryColor)}, 0.5) !important`,
    },
    "@global .MuiButton-textPrimary": {
        color: primaryColor,
    },
    "@global .ant-spin-dot-item": {
        background: primaryColor,
    },
    "@global .MuiTab-textColorPrimary": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.54) !important`,
    },
    "@global #overrideButtonText .MuiButtonBase-root.MuiButton-root.MuiButton-text": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.87)`,
    },
    "@global .overrideButtonText .MuiPaper-root": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.87)`,
    },
    "@global .MuiInputBase-input": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.87)`,
    },
    "@global .MuiFormLabel-root": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.54)`,
    },
    "@global .MuiTypography-colorTextSecondary": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.54)`,
    },
    "@global #white .ant-spin-dot-item": {
        background: "white",
    },
    "@global .ant-progress-line": {
        width: "90%",
    },
    "@global .MuiSlider-markLabel": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.5)`,
    },
    "@global .ant-progress-text": {
        color: `rgba(${hexToRgb(secondaryColor)}, 0.45) !important`,
    },
    "@global .ant-progress-bg": {
        backgroundColor: `${primaryColor} !important`,
    },
    "@global .fileContainer .deleteImage": {
        background: `${primaryColor} !important`,
    },
    "@global .MuiRadio-colorSecondary.Mui-checked": {
        color: `${primaryColor} !important`,
    },
    "@global .MuiRadio-colorSecondary.Mui-checked:hover ": {
        backgroundColor: `rgba(${hexToRgb(primaryColor)}, 0.04) !important`,
    },
    "@global .MuiIconButton-colorSecondary:hover": {
        backgroundColor: `rgba(${hexToRgb(primaryColor)}, 0.04) !important`,
    },
    "@global .MuiPickersToolbar-toolbar, .MuiPickerDTTabs-tabs, .MuiPickersDay-daySelected, .MuiPickersClockPointer-noPoint, .MuiPickersClock-pin, .MuiPickersClockPointer-pointer": {
        backgroundColor: `${primaryColor} !important`,
    },
    "@global .MuiPickersClockPointer-thumb": {
        borderColor: `${primaryColor} !important`,
    },
    "@global .MuiPickersYearSelection-container .MuiTypography-colorPrimary": {
        color: `${primaryColor} !important`,
    },
}));

export default useStyles;
