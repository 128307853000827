export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export const SET_SESSION = "SET_SESSION";
export const CLEAR_SESSION = "CLEAR_SESSION";

export const RESET_CHAT = "RESET_CHAT";
export const ADD_TO_CHAT = "ADD_TO_CHAT";
export const REMOVE_LAST_ITEM_FROM_CHAT = "REMOVE_LAST_ITEM_FROM_CHAT";
export const SET_IS_GAPI_READY = "SET_IS_GAPI_READY";
export const STOP_ALL_AUDIO = "STOP_ALL_AUDIO";
export const ADD_AUDIO = "ADD_AUDIO";
export const SET_IS_PERFORMING_ACTION = "SET_IS_PERFORMING_ACTION";
export const SET_IS_ON_SCREEN_KEYBOARD = "SET_IS_ON_SCREEN_KEYBOARD";
export const SET_BOT_ID = "SET_BOT_ID"

export const SET_RECORDING = "SET_RECORDING";
export const SET_IS_THINKING = "SET_IS_THINKING";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_RECEPTIONIST = "SET_RECEPTIONIST";
export const SET_IS_USER = "SET_IS_USER";
export const SET_HAS_BOWED = "SET_HAS_BOWED";
export const SET_IS_USER_FALSE_COUNT = "SET_IS_USER_FALSE_COUNT";

export const SET_DETECTION_INTERVAL = "SET_DETECTION_INTERVAL";
export const SET_DETECTION = "SET_DETECTION";
export const SET_DETECTION_IMG = "SET_DETECTION_IMG";
export const SET_IS_REGISTERING = "SET_IS_REGISTERING";
export const ADD_TO_REGISTRATION_IMAGES = "ADD_TO_REGISTRATION_IMAGES";
export const CLEAR_REGISTRATION_IMAGES = "CLEAR_REGISTRATION_IMAGES";
export const SET_CAMERA_INFO = "SET_CAMERA_INFO";

export const SET_DETECTIONS = "SET_DETECTIONS";

export const SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED =
    "SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_CURRENT_USER_INFO = "SET_CURRENT_USER_INFO";
export const CLEAR_USER = "CLEAR_USER";
export const CLEAR_USER_FACE_ID = "CLEAR_USER_FACE_ID";
export const DECREASE_RECOGNIZED_COUNT = "DECREASE_RECOGNIZED_COUNT";
export const INCREASE_RECOGNIZED_COUNT = "INCREASE_RECOGNIZED_COUNT";
export const RESET_RECOGNIZED_COUNT = "RESET_RECOGNIZED_COUNT";
export const SET_CAN_USE_RECOGNITION_API = " SET_CAN_USE_RECOGNITION_API";

export const SET_APPEARANCE_SETTINGS = "SET_APPEARANCE_SETTINGS";
export const SET_UPDATE_SETTINGS_FROM_DB = "SET_UPDATE_SETTINGS_FROM_DB";
export const SET_APPLICATION_SETTINGS = "SET_APPLICATION_SETTINGS";
export const SET_OTHER_SETTINGS = "SET_OTHER_SETTINGS";
export const SET_IS_THEME_OVERRIDDEN = "SET_IS_THEME_OVERRIDDEN";

export const SET_KBOT_BUTTON_CONFIGS = "SET_KBOT_BUTTON_CONFIGS";
