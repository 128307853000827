import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import uuid from "react-uuid";
import { base64MimeType } from "./useAvatarSettings";

const uploadURL = "/api/v1/uploads/";

export const usePhotoSettings = (
    dbField,
    tabIndex,
    settingsRef,
    tabRef,
    isModalOpen,
    photoList = false
) => {
    const [pictures, setPictures] = useState([]);
    const [defaultImages, setDefaultImages] = useState([]);
    const [refinedPictures, setRefinedPictures] = useState([]);
    const [selectedBackground, setSelectedBackground] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);

    const photoRef = useRef(null);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );

    const getDefaultImages = () => {
        let timer = null;
        if (settingsRef.current !== null ) {
            if (photoList) {
                // console.log(settingsRef.current[0][dbField]);
                const defaultImages_ = settingsRef.current[dbField].map(
                    (picture) => `${uploadURL}${picture.fileId}`
                );
                // console.log(defaultImages_);
                setDefaultImages(defaultImages_);
                setRefinedPictures(settingsRef.current[dbField]);
                setPictures(settingsRef.current[dbField]);
                let selectedBackground_ = settingsRef.current[dbField].find(
                    (background) =>
                        background.fileName ===
                        settingsRef.current.selectedBackground
                ).fileName;
                setSelectedBackground(selectedBackground_);
            } else {
                const defaultImage_ = [
                    `${uploadURL}${settingsRef.current[dbField].fileId}`,
                ];
                setDefaultImages(defaultImage_);
                setPictures([settingsRef.current[dbField]]);
                setRefinedPictures([settingsRef.current[dbField]]);
            }
        } else {
            timer = setTimeout(getDefaultImages, 1000);
        }
        return timer;
    };

    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = getDefaultImages();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
        if (tabRef.current === tabIndex && isModalOpen) {
            const timer = getDefaultImages();
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = getDefaultImages();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    const getPhotoName = useCallback(
        (photo, newPictures) => {
            let name = new RegExp("(?<=name=)(.*)(?=;)", "g").exec(photo);

            // name already exists
            if (name !== null)
                if (
                    newPictures.find(
                        (picture) => picture.fileName === name[0]
                    ) !== undefined
                )
                    name = `${uuid().slice(0, 5)}_${name[0]}`;

            if (name === null) {
                const photo_ = photo.split("/").slice(-1)[0];
                const img = pictures.find((img) => img.fileId === photo_);
                // console.log(img);
                if (img !== undefined) name = img.fileName;
            }
            return Array.isArray(name) ? name[0] : name;
        },
        [pictures]
    );

    const getPhotoId = useCallback(
        (photo) => {
            const photo_ =
                photo.split("/").length > 0
                    ? photo.split("/").slice(-1)[0]
                    : "";
            let id = null;
            const img = pictures.find((picture) => picture.fileId === photo_);
            if (img !== undefined) id = img.fileId;
            return id;
        },
        [pictures]
    );

    const getMimeType = useCallback(
        (photo) => {
            const photo_ =
                photo.split("/").length > 0
                    ? photo.split("/").slice(-1)[0]
                    : "";
            let mimeType = null;
            const mimeType_ = pictures.find(
                (picture) => picture.fileId === photo_
            );
            if (mimeType_ !== undefined) mimeType = mimeType_.mimeType;
            else mimeType = base64MimeType(photo);
            return mimeType;
        },
        [pictures]
    );

    const onDrop = (pictures_, imgUrls) => {
        let newPictures = [];
        // console.log(pictures_);
        // console.log(imgUrls);
        if (photoList) {
            for (let i = 0; i < imgUrls.length; i++) {
                const name = getPhotoName(imgUrls[i], newPictures);
                const id = getPhotoId(imgUrls[i]);
                const newPicture = {
                    file: imgUrls[i],
                    mimeType: getMimeType(imgUrls[i]),
                };
                if (name !== null) newPicture.fileName = name;
                if (id !== null) {
                    newPicture.fileId = id;
                    newPicture.save = false;
                } else newPicture.save = true;
                newPictures.push(newPicture);
            }
            setPictures(newPictures);
            setRefinedPictures(
                newPictures.map((picture) => formatPicObj(picture))
            );

            if (
                newPictures.find(
                    (pic) => pic.fileName === selectedBackground
                ) === undefined
            )
                if (newPictures.length > 0)
                    // selected background is not in newPicture
                    setSelectedBackground(newPictures[0].fileName);
                else {
                    setSelectedBackground("");
                    setDefaultImages([]);
                }
        } else {
            if (imgUrls[imgUrls.length - 1] !== undefined) {
                const newPicture = {
                    file: imgUrls[imgUrls.length - 1],
                    mimeType: getMimeType(imgUrls[imgUrls.length - 1]),
                };
                const name = getPhotoName(newPicture.file, [newPicture]);
                const id = getPhotoId(newPicture.file);
                if (name !== null) newPicture.fileName = name;
                if (id !== null) {
                    newPicture.fileId = id;
                    newPicture.save = false;
                } else newPicture.save = true;
                setPictures([newPicture]);
                setRefinedPictures([formatPicObj(newPicture)]);
            } else setPictures([]);
            if (imgUrls.length === 0) setDefaultImages([]);
        }
    };

    const formatPicObj = useCallback((obj) => {
        const { save, file, ...others } = obj;
        const file_ = file
            .replace(/(?<=name=)(.*)(?=;)/g, "")
            .replace("name=;", "");
        return save ? { file: file_, ...others } : others;
    }, []);

    return useMemo(
        () => ({
            pictures,
            refinedPictures,
            onDrop,
            getDefaultImages,
            defaultImages,
            setSelectedBackground,
            selectedBackground,
            photoRef,
        }),
        [pictures, refinedPictures, defaultImages, selectedBackground, photoRef]
    );
};
