import { useDispatch, useSelector } from "react-redux";
import { createRef, useEffect, useMemo, useState } from "react";
import { addToNotifications } from "../../../store/actions/notificationActions";
import { translate } from "../../../utils/i18n";
import { unFreezeObjOrArray } from "./useAvatarSettings";
import {areObjectsSame, getCurrentBotId} from "../../../store/actions/chatActions";
import uuid from "react-uuid";
import { bots } from "../../../config.json"


export const useKbotSettings = (settingsRef, tabRef, isModalOpen) => {
    const dispatch = useDispatch();
    const [kbotButtonConfigs, setKbotButtonConfigs] = useState([]);
    const [selectedConfig, setSelectedConfig_] = useState(null);
    const [editor, setEditor] = useState(null);

    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const [firstLoad, setFirstLoad] = useState(true);

    const onLoad = () => {
        let timer = null;
        // update field from db
        if (settingsRef.current !== null) {
            setKbotButtonConfigs(settingsRef.current);
            if (settingsRef.current.length > 0)
                setSelectedConfig_(settingsRef.current[0]._id);
        } else {
            timer = setTimeout(onLoad, 1000);
        }
        return timer;
    };
    const setSelectedConfig = (newSelectedConfigId, update = true, config) => {
        if (!newSelectedConfigId) return setSelectedConfig_(null);
        let success = true;
        if (update) success = updateCurrentConfig(config);
        if (success) setSelectedConfig_(newSelectedConfigId);
    };

    const updateCurrentConfig = (kbotButtonConfigs_) => {
        kbotButtonConfigs_ = kbotButtonConfigs_ || kbotButtonConfigs;
        if (kbotButtonConfigs_.length === 0) return true;
        const newConfigString = editor.current.getValue();
        try {
            const config = JSON.parse(newConfigString);
            const newKbotConfigs = unFreezeObjOrArray(kbotButtonConfigs_);
            const updatedConfigIndex = getConfigIndex(selectedConfig);
            const updatedConfig = { ...config };
            if (kbotButtonConfigs_[updatedConfigIndex]._id)
                updatedConfig._id = kbotButtonConfigs_[updatedConfigIndex]._id;
            else
                updatedConfig.tempId =
                    kbotButtonConfigs_[updatedConfigIndex].tempId;
            newKbotConfigs[updatedConfigIndex] = updatedConfig;
            setKbotButtonConfigs(newKbotConfigs);
            return true;
        } catch (error) {
            dispatch(
                addToNotifications({
                    message: translate("Kbot config is not valid!"),
                    type: "ERROR",
                    size: "md",
                    duration: 1,
                })
            );
            return false;
        }
    };
    const hasKbotSettingsChanged = () =>
        !areObjectsSame(settingsRef.current, isSettingsValid(false));

    const isSettingsValid = (returnBool = true) => {
        if (kbotButtonConfigs.length === 0 || editor === null)
            return returnBool ? true : kbotButtonConfigs;
        const configString = editor.current.getValue();
        if (configString.length === 0)
            return returnBool ? true : kbotButtonConfigs;

        try {
            const config = JSON.parse(configString);
            const configIndex = getConfigIndex(selectedConfig);
            if (kbotButtonConfigs[configIndex]._id)
                config._id = kbotButtonConfigs[configIndex]._id;
            else config.tempId = kbotButtonConfigs[configIndex].tempId;
            const currentConfig = getConfig(selectedConfig);
            if (!areObjectsSame(config, currentConfig)) {
                const newKbotConfigs = unFreezeObjOrArray(kbotButtonConfigs);
                const updatedConfigIndex = getConfigIndex(
                    currentConfig._id || currentConfig.tempId
                );
                const updatedConfig = { ...config };
                if (kbotButtonConfigs[updatedConfigIndex]._id)
                    updatedConfig._id =
                        kbotButtonConfigs[updatedConfigIndex]._id;
                else
                    updatedConfig.tempId =
                        kbotButtonConfigs[updatedConfigIndex].tempId;
                newKbotConfigs[updatedConfigIndex] = updatedConfig;
                setKbotButtonConfigs(newKbotConfigs);
                return returnBool ? true : newKbotConfigs;
            }
            return returnBool ? true : kbotButtonConfigs;
        } catch (error) {
            dispatch(
                addToNotifications({
                    message: translate("Kbot config is not valid!"),
                    type: "ERROR",
                    size: "md",
                    duration: 1,
                })
            );
            return returnBool ? false : kbotButtonConfigs;
        }
    };

    const handleDidMount = (config) => (editor, monaco) => {
        const ref = createRef();
        ref.current = monaco;
        monaco.setValue(
            JSON.stringify(
                { ...config, _id: undefined, tempId: undefined, id: undefined },
                null,
                2
            )
        );
        setEditor(ref);
    };
    const getConfig = (configId) =>
        kbotButtonConfigs.find(
            (config) => config._id === configId || config.tempId === configId
        );
    const getConfigIndex = (configId) =>
        kbotButtonConfigs.findIndex(
            (config) => config._id === configId || config.tempId === configId
        );

    const handleDelete = () => {
        const currentConfig = getConfig(selectedConfig);
        const index = getConfigIndex(currentConfig._id || currentConfig.tempId);
        const newKbotConfigs = unFreezeObjOrArray(kbotButtonConfigs);
        newKbotConfigs.splice(index, 1);
        const newSelectedConfig =
            newKbotConfigs.length > 0
                ? newKbotConfigs[newKbotConfigs.length - 1]
                : null;
        setKbotButtonConfigs(newKbotConfigs);
        setSelectedConfig(
            newSelectedConfig?._id || newSelectedConfig?.tempId,
            false
        );
    };

    const handleAddConfig = () => {
        const tempId = uuid();
        const newConfig = unFreezeObjOrArray(kbotButtonConfigs).concat({
            name: "",
            buttonList: [
                {
                    label: {
                        ja: "Write Child Here",
                        en: "Write Child Here",
                        vi: "Write Child Here",
                    },
                    value: {
                        en: "Write Child Here",
                        ja: "Write Child Here",
                        vi: "Write Child Here",
                    },
                },
            ],
            layout: "vertical",
            label: {
                en: "Write Here",
                ja: "Write Here",
                vi: "Write Here",
            },
            botId: getCurrentBotId() || bots[0].bot_id,
            botToken: bots.find(bot => bot.bot_id === getCurrentBotId())?.bot_token || "",
            tempId: tempId, // a temp Id to identify newly created buttons. This will not be sent when updating the config
        });
        if (kbotButtonConfigs.length === 0) {
            setKbotButtonConfigs(newConfig);
            setSelectedConfig(tempId, false);
        } else {
            setSelectedConfig(tempId, true, newConfig);
        }
    };

    const getStrippedKbotConfig = () => {
        // remove tempId from configs
        const strippedConfig = isSettingsValid(false);
        return strippedConfig.map((config) => {
            delete config["tempId"];
            return config;
        });
    };

    useEffect(() => {
        if (firstLoad && isModalOpen) {
            const timer = onLoad();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [tabRef.current, isModalOpen]);

    useEffect(() => {
        if (updateSettings) {
            const timer = onLoad();
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return useMemo(
        () => ({
            kbotButtonConfigs,
            setKbotButtonConfigs,
            selectedConfig,
            setSelectedConfig,
            editor,
            handleAddConfig,
            handleDelete,
            handleDidMount,
            hasKbotSettingsChanged,
            isSettingsValid,
            updateCurrentConfig,
            getStrippedKbotConfig,
        }),
        [kbotButtonConfigs, selectedConfig, editor, updateSettings]
    );
};
