import {
    SET_IS_USER,
    SET_IS_USER_FALSE_COUNT,
    SET_DETECTION_INTERVAL,
    SET_DETECTIONS,
    SET_DETECTION,
    SET_DETECTION_IMG,
    ADD_TO_REGISTRATION_IMAGES,
    CLEAR_REGISTRATION_IMAGES,
    SET_IS_REGISTERING,
    SET_HAS_BOWED,
    SET_CAMERA_INFO,
} from "../actions/types";

const initialState = {
    is_user: false,
    hasBowed: false,
    count: 0,
    detectionInterval: parseInt(process.env.REACT_APP_DETECTION_INTERVAL),
    detections: [],
    detection: null,
    detectionImg: null,
    registrationImages: [],
    cameraInfo: null,
    isRegistering: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_IS_USER:
            return {
                ...state,
                is_user: action.is_user,
            };
        case SET_IS_USER_FALSE_COUNT:
            if (action.increase) {
                return {
                    ...state,
                    count: state.count + 1,
                };
            } else {
                return {
                    ...state,
                    count: action.count,
                };
            }
        case SET_DETECTION_INTERVAL:
            return {
                ...state,
                detectionInterval: action.detectionInterval,
            };
        case SET_DETECTIONS:
            return {
                ...state,
                detections: action.detections,
            };
        case SET_DETECTION:
            return {
                ...state,
                detection: action.detection,
            };
        case SET_DETECTION_IMG:
            return {
                ...state,
                detectionImg: action.detectionImg,
            };
        case ADD_TO_REGISTRATION_IMAGES:
            return {
                ...state,
                registrationImages: [
                    ...state.registrationImages,
                    action.registrationImg,
                ],
            };
        case CLEAR_REGISTRATION_IMAGES:
            return {
                ...state,
                registrationImages: [],
            };
        case SET_IS_REGISTERING:
            return {
                ...state,
                isRegistering: action.isRegistering,
            };
        case SET_HAS_BOWED:
            return {
                ...state,
                hasBowed: action.hasBowed,
            };
        case SET_CAMERA_INFO:
            return {
                ...state,
                cameraInfo: action.cameraInfo,
            };

        default:
            return state;
    }
}
