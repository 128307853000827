import React, {useEffect, useMemo, useState} from "react";
import useStyles from "../../styles/pages/HomePageStyles";
import FaceCam from "../UI/FaceCam";
import LangSelection from "../UI/LangSelection";
import {languageCodes, languageDisplay, languageSelectionLabels} from "../../styles/constants";
import { useAppearanceSettings } from "../../hooks/settings/useAppearanceSettings";
import { useApplicationSettings } from "../../hooks/settings/useApplicationSettings";
import PropTypes from "prop-types";
import {useWindowSize} from "../../hooks/useWindowSize";
import sizes from "../../styles/sizes"
// import {OnScreenKeyboard} from "../UI/OnScreenKeyboard";

const DEFAULT_TEXT_SETTINGS = { text: null, styles: {} };

const HomePage = ({ history }) => {
    const classes = useStyles();
    const { specialLabel = DEFAULT_TEXT_SETTINGS } = useAppearanceSettings([
        "specialLabel",
    ]);
    const { supportedLanguages = null } = useApplicationSettings([
        "supportedLanguages",
    ]);

    // const isOnScreenKeyboard = useSelector(state => state.chat.isOnScreenKeyboard)

    const getSpecialLabel = useMemo(
        () =>
            specialLabel.text !== null && specialLabel.text.length > 0
                ? specialLabel.text.split("<br/>")
                : null,
        [specialLabel]
    );

    const getLanguages = useMemo(() => {
        if (supportedLanguages !== null && supportedLanguages.length > 0)
            return supportedLanguages.map((lang) => ({
                text: languageDisplay[lang],
                code: languageCodes[lang],
            }));
        else
            return Object.keys(languageCodes).map((lang) => ({
                text: languageDisplay[lang],
                code: languageCodes[lang],
            }));
    }, [supportedLanguages]);

    const [cameraHeight, setCameraHeight] = useState(240);
    const [width] = useWindowSize();
    const faceCamStyle = useMemo(
        () => ({ position: "absolute", right: 0, marginTop: "2em" }),
        [width]
    );

    useEffect(()=>{
        const {sizesNoPx} = sizes
        if(width < parseInt(sizesNoPx["sm"])) {
            setCameraHeight(80)
        }
        else if(width < parseInt(sizesNoPx["md"])) {
            setCameraHeight(120)
        }
        else if(width < parseInt(sizesNoPx["md2"])) {
            setCameraHeight(140)
        }
        else if(width < parseInt(sizesNoPx["md2"])-100) {
            setCameraHeight(180)
        }
        else if(width < parseInt(sizesNoPx["lg"])) {
            setCameraHeight(200)
        }
        else
            setCameraHeight(240)
    }, [width])

    return (
        <>
            <div className={classes.subContainer}>
                <div className={classes.subContainer2}>
                    <LangSelection
                        titles={getSpecialLabel || languageSelectionLabels}
                        style={specialLabel.styles}
                        languages={getLanguages}
                    />
                    <FaceCam style={faceCamStyle} height={cameraHeight}/>
                </div>
            </div>
            {/*{isOnScreenKeyboard && <OnScreenKeyboard/>}*/}
        </>
    );
};

HomePage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
};

export default HomePage;
