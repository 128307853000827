import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    mapImg:{
        width: "100%"
    },
    modalContent:{
        padding: `0 !important`,
        display: "flex",
        alignItems: "center"
    }

}));

export default useStyles;
