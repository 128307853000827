import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../styles/constants";
import { setIsThemeOverridden } from "../../store/actions/settingsActions";
import {createTheme} from "@material-ui/core/styles";

Object.byString = function (o, s) {
    s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
    s = s.replace(/^\./, ""); // strip a leading dot
    let a = s.split(".");
    try {
        for (let i = 0, n = a.length; i < n; ++i) {
            let k = a[i];
            if (k in o) {
                o = o[k];
            } else {
                return undefined;
            }
        }
    } catch (e) {
        return undefined;
    }
    return o;
};

export const useThemeSettings = (fields) => {
    const dispatch = useDispatch();
    const updateSettings = useSelector(
        (state) => state.settings.updateSettings
    );
    const appearanceSettings = useSelector(
        (state) => state.settings.appearanceSettings
    );
    const appearanceSettingsRef = useRef(appearanceSettings);
    appearanceSettingsRef.current = appearanceSettings;
    const [firstLoad, setFirstLoad] = useState(true);
    const [theme, setTheme] = useState({ ...colors, ...createTheme() });

    const overrideDefaultThemeSettings = () => {
        let timer = null;
        if (
            appearanceSettingsRef.current !== null
        ) {
            const newReturn = colors;
            for (const field of fields) {
                const newFieldName = field.replace(".", "_");
                newReturn[newFieldName] =
                    Object.byString(
                        appearanceSettingsRef.current.theme,
                        field
                    ) || colors[field];
            }
            setTheme({ ...newReturn, ...createTheme() });
            // updateColors(newReturn)
            dispatch(setIsThemeOverridden(true));
        } else timer = setTimeout(overrideDefaultThemeSettings, 500);
        return timer;
    };

    useEffect(() => {
        if (updateSettings || firstLoad) {
            const timer = overrideDefaultThemeSettings();
            setFirstLoad(false);
            return () => clearTimeout(timer);
        }
    }, [updateSettings]);

    return theme;
};
