import axios from "axios";
import {
    ADD_NOTIFICATION,
    CLEAR_USER,
    CLEAR_USER_FACE_ID,
    DECREASE_RECOGNIZED_COUNT,
    RESET_RECOGNIZED_COUNT,
    INCREASE_RECOGNIZED_COUNT,
    SET_CAN_USE_RECOGNITION_API,
    SET_CURRENT_USER,
    SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED,
    SET_CURRENT_USER_INFO,
} from "./types";
import { clearRegistrationImages, setIsRegistering } from "./faceCamActions";
import { translate } from "../../utils/i18n";

let recognitionFalseCount = 0;
const url = process.env.REACT_APP_RECOGNITION_AND_REGISTER_API;
const company = process.env.REACT_APP_COMPANY_NAME;
const maxRecognitionFalseCount = parseInt(
    process.env.REACT_APP_RECOGNITION_FALSE_COUNT
);

export const registerUser = (
    user,
    registrationImages,
    recognitionURL
) => async (dispatch) => {
    const registrationImagesStripped = [];
    registrationImages.forEach((image) =>
        registrationImagesStripped.push(
            image.replace("data:image/jpeg;base64,", "")
        )
    );

    // create user in mongodb
    const _user = await dispatch(createUser(user));

    if (!_user) {
        // if user creation goes wrong
        dispatch(setIsRegistering(false));
        dispatch(clearRegistrationImages());
        dispatch({
            type: ADD_NOTIFICATION,
            payload: {
                type: "ERROR",
                message: translate("Something went wrong!"),
                size: "sm",
            },
        });
        return;
    }

    // register for faceAPI
    const formData = {
        images: registrationImagesStripped,
        user_id: _user._id,
        company,
    };
    const URL = `${recognitionURL || url}/v2/register`;
    await axios
        .post(URL, formData)
        .then((res) => {
            dispatch(setIsRegistering(false));
            dispatch(clearRegistrationImages());
            dispatch(
                updateUser(_user._id, {
                    faceId: _user._id,
                    hasUserRegisteredForFace: true,
                })
            ); // update user info if registration was successful

            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "SUCCESS",
                    message: translate("Registered user!"),
                    size: "md",
                },
            });
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

export const recognizeUser = (image, recognitionURL) => async (dispatch) => {
    const formData = {
        face: image.replace("data:image/jpeg;base64,", ""),
        company,
    };
    const URL = `${recognitionURL || url}/v2/recognize`;
    await axios
        .post(URL, formData)
        .then((res) => {
            // console.log(res.data);
            if (res.data?.spoofed) {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "WARNING",
                        message: translate("Spoofed image is detected!"),
                        size: "md",
                    },
                });
                dispatch(clearUser());
            } else if (
                res.data.user_id !== null &&
                res.data.user_id !== undefined
            ) {
                dispatch({
                    type: SET_CURRENT_USER,
                    userFaceId: res.data.user_id,
                });
            } else {
                if (
                    recognitionFalseCount !== 0 &&
                    recognitionFalseCount % maxRecognitionFalseCount === 0
                ) {
                    dispatch(clearUser());
                    recognitionFalseCount = 0;
                } else recognitionFalseCount++;
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err.message);

            if (err.code === "ECONNABORTED") {
                // if request not timed out
                dispatch(clearUserFaceId());
                dispatch(setCanUseRecognitionAPI(false));
                setTimeout(() => dispatch(setCanUseRecognitionAPI(true)), 3000);
            } else {
                dispatch(clearUser());
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            }
        });
};

export const getUsers = (queryString = "") => async (dispatch) => {
    return await axios
        .get(`/api/v1/users/?${queryString}`)
        .then((res) => {
            if (res.data.success) {
                return res.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const getUserById = (userId) => async (dispatch) => {
    return await axios
        .get(`/api/v1/users/${userId}`)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_CURRENT_USER_INFO,
                    userInfo: res.data.data,
                });
                return res.data.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const getUserByFaceId = (faceId) => async (dispatch) => {
    return await axios
        .get(`/api/v1/users/faceId/${faceId}`)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_CURRENT_USER_INFO,
                    userInfo: res.data.data,
                });
                return res.data.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch(async (err) => {
            if (
                err.response &&
                err.response.status === 404 &&
                /\s/g.test(faceId)
            ) {
                // if user does not exist and id is like a name e.g "oketola praise". then create user
                const user = {
                    faceId,
                    firstName: faceId.split(" ")[0],
                    lastName: faceId.substr(faceId.indexOf(" ") + 1),
                    displayName: faceId,
                    hasUserRegisteredForFace: true,
                    role: "staff", // role is staff because every with id like "oketola praise" currently is a staff
                };
                return await dispatch(createUser(user));
            } else {
                console.log(err?.response?.data?.error || err);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        });
};

export const createUser = (user = {}) => async (dispatch) => {
    return await axios
        .post("/api/v1/users", user)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_CURRENT_USER_INFO,
                    userInfo: res.data.data,
                });
                return res.data.data;
            } else {
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch(async (err) => {
            if (err.response && err.response.status === 400) {
                // if user already exists
                return await dispatch(getUserByFaceId(user.faceId));
            } else {
                console.log(err?.response?.data?.error || err);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        });
};

export const updateUser = (userId, updatedUser = {}) => async (dispatch) => {
    return await axios
        .put(`/api/v1/users/${userId}`, updatedUser)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_CURRENT_USER_INFO,
                    userInfo: res.data.data,
                });
                return res.data.data;
            } else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
                return null;
            }
        })
        .catch((err) => {
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const deleteUser = (userId) => async (dispatch) => {
    return await axios
        .delete(`/api/v1/users/${userId}`)
        .then((res) => {
            if (res.data.success) {
                dispatch({
                    type: SET_CURRENT_USER_INFO,
                    userInfo: {},
                });
            } else
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            return res.data.success;
        })
        .catch((err) => {
            console.log(err?.response?.data?.error || err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
            return null;
        });
};

export const setSpoofingDetection = (spoofing = true, recognitionURL) => async (
    dispatch
) => {
    const URL = `${recognitionURL || url}/config/?spoofing=${spoofing}`;

    axios
        .get(URL)
        .then((res) => {
            // console.log(res);
            if (res.data.status === "OK")
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "SUCCESS",
                        message: spoofing
                            ? translate("Spoofing detection is now activated!")
                            : translate(
                                  "Spoofing detection is now deactivated!"
                              ),
                        size: "md",
                    },
                });
            else {
                // debug
                console.log(res.data);
                dispatch({
                    type: ADD_NOTIFICATION,
                    payload: {
                        type: "ERROR",
                        message: translate("Something went wrong!"),
                        size: "sm",
                    },
                });
            }
        })
        .catch((err) => {
            console.log("Error");
            console.log(err);
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "ERROR",
                    message: translate("Something went wrong!"),
                    size: "sm",
                },
            });
        });
};

export const clearUser = () => (dispatch) => {
    dispatch({
        type: CLEAR_USER,
    });
};

export const clearUserFaceId = () => (dispatch) => {
    dispatch({
        type: CLEAR_USER_FACE_ID,
    });
};

export const decreaseRecognizedCount = () => (dispatch) => {
    dispatch({
        type: DECREASE_RECOGNIZED_COUNT,
    });
};

export const increaseRecognizedCount = () => (dispatch) => {
    dispatch({
        type: INCREASE_RECOGNIZED_COUNT,
    });
};
export const resetRecognizedCount = () => (dispatch) => {
    dispatch({
        type: RESET_RECOGNIZED_COUNT,
    });
};

export const setCanUseRecognitionAPI = (canUseRecognitionAPI) => (dispatch) => {
    dispatch({
        type: SET_CAN_USE_RECOGNITION_API,
        canUseRecognitionAPI,
    });
};

export const setHasPreviousCurrentUsersChanged = (
    hasPreviousCurrentUsersChanged
) => async (dispatch) => {
    await dispatch({
        type: SET_HAS_PREVIOUS_CURRENT_USERS_CHANGED,
        hasPreviousCurrentUsersChanged,
    });
};
