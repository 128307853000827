import React from "react";
import Vertical from "./Vertical";
import Horizontal from "./Horizontal";
import PropTypes from "prop-types";
import { Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { sendMessageWithBotID } from "../../../../store/actions/chatActions";
import {useHistory} from "react-router-dom";

const KbotButtonAction = ({ buttonList, layout, botId, botToken, botLang }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const language = useSelector((state) => state.language.currentLanguage);
    const isThinking = useSelector((state) => state.chat.isThinking);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);

    const _clickHandle = (value, display) => {
        if (!isThinking) {
            dispatch(
                sendMessageWithBotID(
                    value,
                    display,
                    botId,
                    botToken,
                    false,
                    history,
                    botLang || language,
                    isGapiReady
                )
            );
        }
    };

    return (
        <Row
            style={{
                width: "100%",
            }}
            justify="center"
            align="middle"
        >
            {layout === "vertical" && (
                <Vertical
                    buttonList={buttonList}
                    onClick={_clickHandle}
                    botLang={botLang}
                />
            )}
            {layout === "horizontal" && (
                <Horizontal
                    buttonList={buttonList}
                    onClick={_clickHandle}
                    botLang={botLang}
                />
            )}
        </Row>
    );
};

KbotButtonAction.propTypes = {
    buttonList: PropTypes.array,
    layout: PropTypes.oneOf(["horizontal", "vertical"]),
};

KbotButtonAction.defaultProps = {
    layout: "horizontal",
};

export default KbotButtonAction;
