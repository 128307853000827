import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import { Button, FormControl, Typography } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import classnames from "classnames";
import MicIcon from "@material-ui/icons/Mic";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { addToNotifications } from "../../../store/actions/notificationActions";
import moment from "moment-timezone";
import {
    decodeDescription,
    getEvents,
} from "../../../store/actions/calendarActions";
import { Spin } from "antd";
import Moment from "react-moment";
import { useApplicationSettings } from "../../../hooks/settings/useApplicationSettings";
import MeetStaffModal, { DEFAULT_CONFIG } from "./MeetStaffModal";
import { useSpeechRecognitionManager } from "../../../hooks/useSpeechRecognitionManager";
import {initCalendarAPI, speechSynthesisChunker} from "../../../store/actions/chatActions";
import { getUsers } from "../../../store/actions/userActions";
import { sendSlackMessage } from "../../../store/actions/slackActions";
import { Transition } from "../settings/Settings";
import {defaultVoiceResponses, languageCodes} from "../../../styles/constants";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles(({ primaryColor, secondaryColor }) => ({
    formControl: {
        margin: "0.5em 0",
    },
    formContainer: {
        margin: "0 5em 1em 5em",
    },
    microphoneRed: {
        color: "red !important",
    },
    infoItem: {
        margin: "0.3em 0",
    },
    space: {
        marginRight: "0.5em",
    },
    chip: {
        margin: "0.3em",
        color: "rgba(14, 13, 13, 0.54)",
    },
    "@global #mic.MuiIconButton-root": {
        color: primaryColor,
    },
    "@global #meeting-dialog-container .MuiTypography-root.MuiTypography-h6": {
        color: "rgba(14, 13, 13, 0.54)",
    },
}));

const FindMeetingModal = ({ open, handleClose }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { slack_config: config = DEFAULT_CONFIG,  voiceResponses = defaultVoiceResponses,} = useApplicationSettings([
        "slack.config",
        "voiceResponses"
    ]);
    const isGapiReady = useSelector((state) => state.chat.isGapiReady);
    const language = useSelector((state) => state.language.currentLanguage);
    const { isUserRecognized, userInfo } = useSelector((state) => state.user);

    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({});
    const [meeting, setMeeting] = useState(null);
    const [meetingAttendees, setMeetingAttendees] = useState([]);
    const [isMeetingInfoModalOpen, setIsMeetingInfoModalOpen] = useState(false);
    const [isMeetStaffModalOpen, setIsMeetStaffModalOpen] = useState(false);
    const [
        transcript,
        isRecording,
        handleToggle,
        resetTranscript,
    ] = useSpeechRecognitionManager(null, false, true);

    const handleMeetStaff = useCallback(
        () => setIsMeetStaffModalOpen(true),
        []
    );
    const handleMeetStaffModalClose = useCallback(
        () => setIsMeetStaffModalOpen(false),
        []
    );
    const handleMeetingInfoModalClose = useCallback(
        () => setIsMeetingInfoModalOpen(false),
        []
    );

    useEffect(() => {
        if (transcript.length > 0)
            setForm({
                ...form,
                name: transcript,
            });
    }, [transcript]);

    useEffect(() => {
        if (open && isUserRecognized && Object.keys(userInfo).length !== 0) {
            // if user is recognized auto fill form and submit
            const user = {
                name: userInfo.displayName,
                email: userInfo.email,
                isStaff: userInfo.role === "staff",
            };
            setForm(user);
            handleSubmit(user);
        } else if (open) {
            setForm({});
        }
    }, [open]);

    const setFormValue = (field) => (event) => {
        setForm({
            ...form,
            [field]: event.target.value,
        });
    };

    const handleSubmit = async (form) => {
        const { name, email } = form;
        const _name = name?.length === 0 ? undefined : name;
        const _email = email?.length === 0 ? undefined : email;
        const _form = { name: _name, email: _email, ...form };
        if (!_name && !_email) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Fill in at least 1 field!"),
                    size: "md",
                })
            );
        } else {
            setIsLoading(true);
            await handleFindMeeting(_form);
            setIsLoading(false);
        }
    };

    const handleFindMeeting = async (form) => {
        await dispatch(initCalendarAPI());
        let meetingForUser;
        const timeMin = new Date(moment().startOf("day").toString());
        const timeMax = new Date(moment().endOf("day").toString());
        const isSignedIn = window.gapi.auth2
            ?.getAuthInstance()
            ?.isSignedIn?.get();
        if (!isSignedIn) {
            handleNoMeetingFound(form);
            return dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate(
                        "Cannot find meeting because app is not signed in!"
                    ),
                    size: "md",
                })
            );
        }

        const events = await dispatch(getEvents(timeMin, timeMax));
        if (!events || events.items.length === 0) {
            return handleNoMeetingFound(form);
        }

        meetingForUser = findMeeting(events, form);

        if (!meetingForUser) {
            handleNoMeetingFound(form);
        } else {
            handleMeetingFound(meetingForUser, form);
        }
    };

    const handleNoMeetingFound = useCallback((form) => {
        handleClose();
        let textOnNoMeetingFound =  voiceResponses.meeting?.meeting?.onFindMeetingFail?.unknown?.[language]?.replace("<name>", form?.name || "")
        if (isUserRecognized){
            textOnNoMeetingFound = voiceResponses.meeting?.meeting?.onFindMeetingFail?.known?.[language]?.replace("<name>", form?.name || "")
        }
        dispatch(
            speechSynthesisChunker(textOnNoMeetingFound, language, isGapiReady)
        );
        handleMeetStaff();
    }, [language, isGapiReady, voiceResponses, isUserRecognized, userInfo]);

    const handleMeetingFound = (meeting, form) => {
        let textOnMeetingFound = voiceResponses.meeting?.meeting?.onFindMeetingSuccess?.unknown?.[language]
            ?.replace("<name>", form?.name || "")
            ?.replace("<location>", meeting.location || "")
            ?.replace("<time>", postProcessDate(formatDate(meeting.start.dateTime, "LT")) || "")
        if (isUserRecognized) {
            textOnMeetingFound = voiceResponses.meeting?.meeting?.onFindMeetingSuccess?.known?.[language]
                ?.replace("<name>", form?.name || "")
                ?.replace("<location>", meeting.location || "")
                ?.replace("<time>", postProcessDate(formatDate(meeting.start.dateTime, "LT")) || "")
        }
        dispatch(
            speechSynthesisChunker(textOnMeetingFound, language, isGapiReady)
        );
        setMeeting(meeting);
        setMeetingAttendees(meeting._attendees);
        setIsMeetingInfoModalOpen(true);
    };

    const findMeeting = (events, form) => {
        let meetingForUser = null;
        const _events = events.items.map((event) => {
            // decodeDescription of every event
            const {
                additionalInfo: { attendees = [] },
            } = decodeDescription(event.description);
            return { ...event, _attendees: attendees };
        });

        const searchEvent = (form, searchBy) => {
            return _events.find((event) => {
                return !!event._attendees.find((attendee) => {
                    switch (searchBy) {
                        case "all":
                            return (
                                attendee.email === form.email &&
                                compareNames(attendee.displayName, form.name) &&
                                attendee.isStaff === !!form.isStaff
                            );
                        case "one":
                            let isAttendeeFound = false;
                            if (
                                form.email &&
                                attendee.email &&
                                !isAttendeeFound
                            )
                                isAttendeeFound =
                                    attendee.email === form.email &&
                                    attendee.isStaff === !!form.isStaff;
                            if (
                                form.name &&
                                attendee.displayName &&
                                !isAttendeeFound
                            )
                                isAttendeeFound =
                                    compareNames(
                                        attendee.displayName,
                                        form.name
                                    ) && attendee.isStaff === !!form.isStaff;
                            return isAttendeeFound;
                        case "email":
                            return (
                                form.email &&
                                attendee.email &&
                                attendee.email === form.email &&
                                attendee.isStaff === !!form.isStaff
                            );
                        case "name":
                            return (
                                form.name &&
                                attendee.displayName &&
                                compareNames(attendee.displayName, form.name) &&
                                attendee.isStaff === !!form.isStaff
                            );
                    }
                });
            });
        };

        if (form.email && form.name) {
            // user provides both email and name
            meetingForUser = searchEvent(form, "all");
            if (!meetingForUser) {
                // meeting not found, search by email then name
                meetingForUser = searchEvent(form, "email");
                if (!meetingForUser) meetingForUser = searchEvent(form, "name");
            }
        } else {
            meetingForUser = searchEvent(form, "one");
        }

        return meetingForUser;
    };

    const handleNotifyStaff = async () => {
        setIsLoading(true);

        // get users where role=staff and slackId is not null
        const queryString = `slackId[regex]=${encodeURIComponent(
            ".+"
        )}&role=staff&limit=500`;
        const staffs = await dispatch(getUsers(queryString));

        if (staffs?.success) {
            const staffsInAttendees = meeting._attendees.filter(
                (attendee) => attendee.isStaff
            );
            const validStaffsInAttendees = staffs.data.filter((staff) => {
                return !!staffsInAttendees.find(
                    (attendee) =>
                        attendee.displayName.toLowerCase() ===
                            staff.slackId.toLowerCase() ||
                        attendee.displayName.toLowerCase() ===
                            staff.displayName.toLowerCase() ||
                        (attendee.email && staff.email
                            ? attendee.email.toLowerCase() ===
                              staff.email.toLowerCase()
                            : false)
                );
            });
            let speakOnSuccess = voiceResponses?.meeting?.meeting?.after?.unknown?.[language]?.replace("<name>", form.name)
            if (isUserRecognized) speakOnSuccess = voiceResponses?.meeting?.meeting?.after?.known?.[language]?.replace("<name>", userInfo.displayName)
            await dispatch(
                sendSlackMessage({
                    message: translate(
                        `${
                            form.name || form.email
                        } is here for the meeting '${
                            meeting.summary
                        }' scheduled to begin ${formatDate(
                            meeting.start.dateTime
                        )}`
                    ),
                    staffSlackId: validStaffsInAttendees.map(
                        (attendee) => attendee.slackId
                    ),
                    channel: config.meeting.channels,
                    speakOnSuccess,
                    language,
                    isGapiReady,
                })
            );
        }
        handleClose();
        resetForm();
        setIsLoading(false);
    };

    const handleError = useCallback(
        (type) => {
            const value = form[type];
            switch (type) {
                case "email":
                    if (
                        value?.length > 0 &&
                        !value.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
                    )
                        return {
                            error: true,
                            text: "Field must be a valid mail",
                        };
                    else return { error: false, text: null };
            }
        },
        [form]
    );

    const handleSubmitAndRegister = async() => {
        const username = form.name
        await handleNotifyStaff();
        if (!isUserRecognized) {
            const state = {
                firstName: username,
                lastName: "",
                displayName: username,
                role: "guest",
                redirectTo: "/reception",
                showRegistrationModal: false,
            };
            history.push({
                pathname: "/face-registration",
                state,
            });
        } else {
            dispatch(
                addToNotifications({
                    type: "INFO",
                    message: translate("You are already registered!"),
                    size: "md",
                })
            );
        }
    };

    const removeAccents = (str) =>
        str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    const compareNames = (name1, name2) => {
        const _name1 = removeAccents(name1.toLowerCase());
        const _name2 = removeAccents(name2.toLowerCase());
        return !!(_name2.includes(_name1) || _name1.includes(_name2));
    };

    const formatDate = (date, format="HH:mm DD/MM/YYYY") => moment(date).format(format);

    const postProcessDate = useCallback((date)=>{
        switch (language){
            case languageCodes.japanese:
                return `${date.replace(':', '時')}分`
            default: return date
        }
    }, [language])

    const dateFormat = useMemo(() => {
        if (language === languageCodes.japanese)
            return "A hh:mm"
        else return "hh:mm A"
    }, [language]);

    const resetForm = () => {
        setForm({});
        setMeeting(null);
        setIsMeetingInfoModalOpen(false);
    };

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("Find a meeting")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {translate("Please enter your info")}
                    </DialogContentText>
                    <FormGroup className={classes.formContainer}>
                        <FormControl className={classes.formControl}>
                            <TextField
                                fullWidth
                                required
                                label={translate("Your name")}
                                size="small"
                                value={form["name"] || ""}
                                onChange={setFormValue("name")}
                                placeholder={translate("Enter your name")}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                id={"mic"}
                                                aria-label="toggle mic"
                                                onClick={handleToggle}
                                                className={classnames({
                                                    [classes.microphoneRed]: isRecording,
                                                })}
                                                edge="end"
                                            >
                                                <MicIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <TextField
                                fullWidth
                                required
                                size="small"
                                label={translate("Your email")}
                                value={form["email"] || ""}
                                onChange={setFormValue("email")}
                                placeholder={translate("Enter your email")}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                                error={handleError("email").error}
                                helperText={handleError("email").text}
                            />
                        </FormControl>
                    </FormGroup>
                    <DialogActions className={classes.dialogAction}>
                        <Button
                            onClick={handleClose}
                            color="primary"
                            disabled={isLoading}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button
                            onClick={() => handleSubmit(form)}
                            color="primary"
                            variant={"contained"}
                            disabled={isLoading}
                        >
                            {!isLoading ? (
                                translate("Find Meeting")
                            ) : (
                                <Spin size="small" />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <Dialog
                open={isMeetingInfoModalOpen}
                TransitionComponent={Transition}
                keepMounted={false}
                onClose={handleMeetingInfoModalClose}
                fullWidth={true}
                maxWidth={"sm"}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">
                    {translate("We found you a meeting")}
                </DialogTitle>
                <DialogContent>
                    {meeting && (
                        <div id={"meeting-dialog-container"}>
                            <Typography
                                gutterBottom
                                color="textSecondary"
                                variant="h4"
                                component="h2"
                            >
                                {meeting.summary}
                            </Typography>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                component="h6"
                                className={classes.infoItem}
                            >
                                <span className={classes.space}>
                                    {translate("Time")}:
                                </span>
                                <Moment format={dateFormat}>
                                    {meeting.start.dateTime}
                                </Moment>
                                <span style={{ margin: "0 0.25em" }}>-</span>
                                <Moment format={dateFormat}>
                                    {meeting.end.dateTime}
                                </Moment>
                            </Typography>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                component="h6"
                                className={classes.infoItem}
                            >
                                <span className={classes.space}>
                                    {translate("Location")}:
                                </span>
                                {meeting.location}
                            </Typography>
                            <Typography
                                variant="h6"
                                color="textSecondary"
                                component="h6"
                                className={classes.infoItem}
                            >
                                <span className={classes.space}>
                                    {translate("Attendees")}:
                                </span>

                                {meetingAttendees.map((attendee, index) => (
                                    <span key={index}>
                                        {attendee.displayName.capitalize()}
                                        {index + 1 !== meetingAttendees.length
                                            ? ", "
                                            : ". "}
                                    </span>
                                ))}
                            </Typography>
                        </div>
                    )}

                    <DialogActions className={classes.dialogAction}>
                        <Button
                            onClick={handleMeetingInfoModalClose}
                            color="primary"
                            disabled={isLoading}
                        >
                            {translate("Cancel")}
                        </Button>
                        <Button onClick={handleSubmitAndRegister} color="primary">
                            {translate("Notify and remember my face")}
                        </Button>
                        <Button
                            onClick={handleNotifyStaff}
                            color="primary"
                            variant={"contained"}
                            disabled={isLoading}
                        >
                            {!isLoading ? (
                                translate("Notify Us")
                            ) : (
                                <Spin size="small" />
                            )}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
            <MeetStaffModal
                open={isMeetStaffModalOpen}
                showStaffList
                initialForm={form}
                handleClose={handleMeetStaffModalClose}
                addToDescription={translate("No meetings found")}
            />
        </>
    );
};

FindMeetingModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default FindMeetingModal;
