import React, { useCallback, useEffect, useRef, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { Transition } from "../../../settings/Settings";
import DialogTitle from "@material-ui/core/DialogTitle";
import { translate } from "../../../../../utils/i18n";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import FormGroup from "@material-ui/core/FormGroup";
import {
    Button,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { Autocomplete } from "@material-ui/lab";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import { usePrevious } from "../../../../../hooks/usePrevious";
import uuid from "react-uuid";
import { unFreezeObjOrArray } from "../../../../../hooks/settings/settingsModal/useAvatarSettings";
import { getUsers } from "../../../../../store/actions/userActions";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import InputAdornment from "@material-ui/core/InputAdornment";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import Tooltip from "@material-ui/core/Tooltip";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: "1em 0",
    },
    helperText: {
        marginTop: "0.5em",
        fontSize: "0.8rem",
    },
    icon: {
        marginLeft: "1em",
    },
    switchText: {
        marginBottom: 0,
    },
}));
const AttendeeModal = ({
    open,
    handleClose,
    isUpdatingAttendee,
    setAttendees,
    attendees,
    attendeeToUpdate,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [attendeeName, setAttendeeName] = useState("");
    const [attendeeEmail, setAttendeeEmail] = useState("");
    const [isAttendeeStaff, setIsAttendeeStaff] = useState(false);
    const [attendeeId, setAttendeeId] = useState("");

    const [attendeeNameAutoComplete, setAttendeeNameAutoComplete] = useState(
        []
    );
    const attendeeNameRef = useRef(attendeeName);
    attendeeNameRef.current = attendeeName;
    const previousAttendeeName = usePrevious(attendeeName, "");
    const previousAttendeeNameRef = useRef(previousAttendeeName);
    previousAttendeeNameRef.current = previousAttendeeName;
    const attendeeNameAutoCompleteSearchRef = useRef();
    const [attendeeNameFieldFocused, setAttendeeNameFieldFocused] = useState(
        false
    );

    const [attendeeEmailAutoComplete, setAttendeeEmailAutoComplete] = useState(
        []
    );
    const attendeeEmailRef = useRef(attendeeEmail);
    attendeeEmailRef.current = attendeeEmail;
    const previousAttendeeEmail = usePrevious(attendeeEmail, "");
    const previousAttendeeEmailRef = useRef(previousAttendeeEmail);
    previousAttendeeEmailRef.current = previousAttendeeEmail;
    const attendeeEmailAutoCompleteSearchRef = useRef();
    const [attendeeEmailFieldFocused, setAttendeeEmailFieldFocused] = useState(
        false
    );

    const [isAutoCompleteNameLoading, setIsAutoCompleteNameLoading] = useState(
        false
    );
    const [
        isAutoCompleteEmailLoading,
        setIsAutoCompleteEmailLoading,
    ] = useState(false);

    const handleAttendeeModalClose = useCallback(() => {
        setAttendeeName("");
        setAttendeeEmail("");
        setIsAttendeeStaff(false);
        setAttendeeId("");
        setAttendeeEmailAutoComplete([]);
        setAttendeeNameAutoComplete([]);
        handleClose();
    }, []);

    const handleAddNewAttendee = useCallback(() => {
        setAttendees([
            ...attendees,
            {
                displayName: attendeeName,
                email: attendeeEmail,
                isStaff: isAttendeeStaff,
                id: uuid(),
            },
        ]);
        handleAttendeeModalClose();
    }, [attendeeName, attendeeEmail, isAttendeeStaff]);

    const handleUpdateAttendee = useCallback(() => {
        const updatedAttendee = {
            displayName: attendeeName,
            email: attendeeEmail,
            isStaff: isAttendeeStaff,
            id: attendeeId,
        };
        const updatedAttendees = unFreezeObjOrArray(attendees);
        const attendeeToUpdateIndex = updatedAttendees.findIndex(
            (attendee) => attendee.id === attendeeId
        );
        updatedAttendees[attendeeToUpdateIndex] = updatedAttendee;
        setAttendees(updatedAttendees);
        handleAttendeeModalClose();
    }, [attendees, attendeeName, attendeeEmail, attendeeId, isAttendeeStaff]);

    const handleUpdateOrAddAttendee = useCallback(() => {
        const error = validate();
        if (!error) {
            if (isUpdatingAttendee) handleUpdateAttendee();
            else handleAddNewAttendee();
        }
    }, [isUpdatingAttendee, handleUpdateAttendee, handleAddNewAttendee]);

    const searchUser = useCallback(
        async (field, valueRef, previousValueRef) => {
            if (
                !valueRef.current.length > 0 ||
                valueRef.current.length === previousValueRef.current.length
            ) {
                return;
            }
            let queryString = "";
            if (field === "attendeeName") {
                setIsAutoCompleteNameLoading(true);
                const orQuery = [
                    { slackId: { regex: valueRef.current } },
                    { displayName: { regex: valueRef.current } },
                ];
                queryString = queryString.concat(
                    `&or=${JSON.stringify(orQuery)}`
                );
            } else if (field === "attendeeEmail") {
                setIsAutoCompleteEmailLoading(true);
                queryString = queryString.concat(
                    `&email[regex]=${valueRef.current}`
                );
            }
            const data = await dispatch(getUsers(queryString));
            if (data.data) {
                if (field === "attendeeName") {
                    setAttendeeNameAutoComplete(data.data);
                    setIsAutoCompleteNameLoading(false);
                } else if (field === "attendeeEmail") {
                    setAttendeeEmailAutoComplete(data.data);
                    setIsAutoCompleteEmailLoading(false);
                }
            }
        },
        []
    );

    const handleError = useCallback((type, value) => {
        switch (type) {
            case "email":
                if (
                    value.length > 0 &&
                    !value.match("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$")
                )
                    return { error: true, text: "Field must be a valid mail" };
                return { error: false, text: null };
            default:
                return { error: false, text: null };
        }
    }, []);

    const validate = useCallback(() => {
        const errors =
            attendeeName.length === 0 ||
            (attendeeEmail.length >= 0 &&
                handleError("email", attendeeEmail).error);
        if (errors) {
            dispatch(
                addToNotifications({
                    type: "ERROR",
                    message: translate("Please fill the required fields!"),
                    size: "md",
                })
            );
            return true;
        } else return false;
    }, [attendeeName, attendeeEmail, handleError, dispatch]);

    useEffect(() => {
        if (attendeeNameFieldFocused)
            attendeeNameAutoCompleteSearchRef.current = setInterval(
                () =>
                    searchUser(
                        "attendeeName",
                        attendeeNameRef,
                        previousAttendeeNameRef
                    ),
                1000
            );
        else clearInterval(attendeeNameAutoCompleteSearchRef.current);
        return () => clearInterval(attendeeNameAutoCompleteSearchRef.current);
    }, [attendeeNameFieldFocused]);

    useEffect(() => {
        if (attendeeEmailFieldFocused)
            attendeeEmailAutoCompleteSearchRef.current = setInterval(
                () =>
                    searchUser(
                        "attendeeEmail",
                        attendeeEmailRef,
                        previousAttendeeEmailRef
                    ),
                1000
            );
        else clearInterval(attendeeEmailAutoCompleteSearchRef.current);
        return () => clearInterval(attendeeEmailAutoCompleteSearchRef.current);
    }, [attendeeEmailFieldFocused]);

    useEffect(() => {
        if (open) {
            if (isUpdatingAttendee) {
                const {
                    displayName,
                    email = "",
                    isStaff = false,
                    id,
                } = attendeeToUpdate;
                setAttendeeName(displayName);
                setAttendeeEmail(email);
                setIsAttendeeStaff(isStaff);
                setAttendeeId(id);
            } else {
                setAttendeeName("");
                setAttendeeEmail("");
                setIsAttendeeStaff(false);
                setAttendeeId("");
                setAttendeeEmailAutoComplete([]);
                setAttendeeNameAutoComplete([]);
            }
        }
    }, [open, isUpdatingAttendee]);

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth={"sm"}
            fullWidth
        >
            <DialogTitle id="alert-dialog-slide-title">
                {translate(
                    isUpdatingAttendee ? "Update attendee" : "Add new attendee"
                )}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                    {translate(
                        isUpdatingAttendee
                            ? "Update existing attendee in event"
                            : "Add a new attendee to event"
                    )}
                </DialogContentText>
                <FormGroup className={classes.formContainer}>
                    <FormControl>
                        <Autocomplete
                            freeSolo
                            fullWidth
                            options={attendeeNameAutoComplete}
                            loading={isAutoCompleteNameLoading}
                            classes={{
                                option: classes.option,
                            }}
                            disableClearable
                            getOptionLabel={(option) => option.displayName}
                            inputValue={attendeeName}
                            autoHighlight
                            onChange={(e, value, reason) => {
                                if (reason === "select-option")
                                    setAttendeeName(value.displayName);
                                else if (reason === "clear")
                                    setAttendeeName("");
                            }}
                            renderOption={(option, state) => (
                                <Grid container alignItems="center">
                                    {option.displayName}
                                    {option.role === "staff" && (
                                        <Tooltip
                                            title={translate("Staff")}
                                            arrow={true}
                                            placement={"bottom"}
                                        >
                                            <VerifiedUserIcon
                                                className={classes.icon}
                                            />
                                        </Tooltip>
                                    )}
                                </Grid>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    required
                                    label={translate("Attendee Name")}
                                    size="small"
                                    value={attendeeName}
                                    onChange={(e) =>
                                        setAttendeeName(e.target.value)
                                    }
                                    placeholder={translate(
                                        "Enter attendee name"
                                    )}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    onFocus={(e) =>
                                        setAttendeeNameFieldFocused(true)
                                    }
                                    onBlur={(e) =>
                                        setAttendeeNameFieldFocused(false)
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="search"
                                                    onClick={() => {}}
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    edge="end"
                                                >
                                                    {isAutoCompleteNameLoading && (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            freeSolo
                            fullWidth
                            options={attendeeEmailAutoComplete}
                            loading={isAutoCompleteEmailLoading}
                            classes={{
                                option: classes.option,
                            }}
                            getOptionLabel={(option) => option.email}
                            autoHighlight
                            onChange={(e, value, reason) => {
                                if (reason === "select-option")
                                    setAttendeeEmail(value.email);
                                else if (reason === "clear")
                                    setAttendeeEmail("");
                            }}
                            inputValue={attendeeEmail}
                            disableClearable
                            renderOption={(option, state) => (
                                <Grid container alignItems="center">
                                    {option.email}
                                    {option.role === "staff" && (
                                        <Tooltip
                                            title={translate("Staff")}
                                            arrow={true}
                                            placement={"bottom"}
                                        >
                                            <VerifiedUserIcon
                                                className={classes.icon}
                                            />
                                        </Tooltip>
                                    )}
                                </Grid>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    size="small"
                                    label={translate("Attendee Email")}
                                    value={attendeeEmail}
                                    onChange={(e) =>
                                        setAttendeeEmail(e.target.value)
                                    }
                                    placeholder={translate(
                                        "Enter attendee email"
                                    )}
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    onFocus={(e) =>
                                        setAttendeeEmailFieldFocused(true)
                                    }
                                    onBlur={(e) =>
                                        setAttendeeEmailFieldFocused(false)
                                    }
                                    error={
                                        handleError("email", attendeeEmail)
                                            .error
                                    }
                                    helperText={
                                        handleError("email", attendeeEmail).text
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="search"
                                                    onClick={() => {}}
                                                    onMouseDown={(e) =>
                                                        e.preventDefault()
                                                    }
                                                    edge="end"
                                                >
                                                    {isAutoCompleteEmailLoading && (
                                                        <CircularProgress
                                                            color="inherit"
                                                            size={20}
                                                        />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <DialogContentText
                            id="alert-dialog-slide-description"
                            className={classes.helperText}
                        >
                            {translate(
                                "For staffs, name must match staff's displayName/slackId or email must match staff's email."
                            )}
                        </DialogContentText>
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <DialogContentText
                            id="alert-dialog-slide-description"
                            className={classes.switchText}
                        >
                            {translate("Is Attendee a staff?")}
                        </DialogContentText>
                        <Switch
                            checked={isAttendeeStaff}
                            onChange={(e) =>
                                setIsAttendeeStaff(e.target.checked)
                            }
                            color="primary"
                        />
                    </FormControl>
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAttendeeModalClose} color="primary">
                    {translate("Abort")}
                </Button>
                <Button
                    onClick={handleUpdateOrAddAttendee}
                    color="primary"
                    variant={"contained"}
                >
                    {translate(isUpdatingAttendee ? "Update" : "Add")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AttendeeModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    setAttendees: PropTypes.func.isRequired,
    attendees: PropTypes.array.isRequired,
    attendeeToUpdate: PropTypes.object.isRequired,
};

export default AttendeeModal;
