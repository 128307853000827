import { makeStyles } from "@material-ui/core/styles";
import sizes from "../sizes";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        textAlign: "center",
        marginTop: "7%",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
    },
    langTitleContainer:{
        width: "70%"
    },
    langTitle: {
        fontSize: "1.5rem",
        marginBottom: "1em",
        [sizes.down('md1')]: {
            fontSize: "1.25rem",
        },
    },
    language: {
        fontSize: "1.35rem",
        width: "100%",
        height: "3.5em",
        "&:hover": {
            boxShadow:
                "0 7px 13px -6px rgba(153, 153, 153, 0.2), 0 2px 11.5px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2.5px rgba(153, 153, 153, 0.2) !Important",
        },
        [sizes.down('xl')]: {
            fontSize: "1rem",
        },
    },
    grid: {
        marginTop: "2em",
        width: "75%",
        height: "13em",
        overflowY: "auto",
        justifyContent: "center"
    },
    gridItem: {
        textAlign: "center",
        height: "6em",
    },
    removeDefaultLink: {
        textDecoration: "none !important",
        color: "inherit !important",
    },
}));

export default useStyles;
