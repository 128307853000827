import React, { useCallback, useMemo, useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { hexToRgb } from "../../../../../styles/material-dashboard-react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Checkbox, IconButton } from "@material-ui/core";
import { translate } from "../../../../../utils/i18n";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import ConfirmationModal from "../../../modals/ConfirmationModal";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { deleteUser } from "../../../../../store/actions/userActions";
import { ADD_NOTIFICATION } from "../../../../../store/actions/types";
import {
    downloadFileWithDepositionAttachment,
    getTrackersByUsersExcel,
} from "../../../../../store/actions/checkInCheckOutActions";
import { addToNotifications } from "../../../../../store/actions/notificationActions";
import { DateTimePicker } from "@material-ui/pickers";
import reactStringReplace from "react-string-replace";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import UserCard from "./UserCard";

export const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: `rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`,
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        padding: 0,
    },
    title: {
        fontSize: "1.125rem",
    },
    icon: {
        marginRight: "1.5em",
        padding: "0.25em",
        color: "white !important",
    },
    toolbarContainer: {
        width: "100%",
    },
    dateSettingsContainer: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.25rem",
        "& input": {
            color: "white !important",
        },
        color: "white !important",
    },
    combinedCells: {
        display: "flex",
        justifyContent: "flex-end",
    },
    cell: {
        width: "7em",
    },
    "@global #userContent .MuiInput-underline:before": {
        borderBottomColor: "white !important",
    },
    "@global #userContent .MuiInput-underline:after": {
        borderBottomColor: "white !important",
    },

    "@global #userContent::-webkit-scrollbar-track": {
        webkitBoxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
        backgroundColor: "#f5f5f5",
    },

    "@global #userContent::-webkit-scrollbar": {
        width: "4px",
        height: "4px",
        backgroundColor: "#f5f5f5",
    },

    "@global #userContent::-webkit-scrollbar-thumb": {
        border: `2px solid rgba(${hexToRgb(theme.palette.common.black)}, 0.5)`,
    },
}));

const TableToolbar = ({
    numSelected,
    handleDeleteSelected,
    handleExportToExcel,
    isLoading,
    indeterminate,
    checked,
    onChange,
    renderDateSettings,
}) => {
    const classes = useToolbarStyles();
    const [
        isDeleteConfirmationDialogOpen,
        setIsDeleteConfirmationDialogOpen,
    ] = useState(false);
    const [
        isExportConfirmationDialogOpen,
        setIsExportConfirmationDialogOpen,
    ] = useState(false);

    const onDeleteSelected = async () => {
        await handleDeleteSelected();
        setIsDeleteConfirmationDialogOpen(false);
    };

    const onExportSelected = async () => {
        await handleExportToExcel();
        setIsExportConfirmationDialogOpen(false);
    };

    return (
        <TableHead className={classes.root}>
            {numSelected === 0 && (
                <TableRow>
                    <StyledTableCell padding="checkbox">
                        <Checkbox
                            indeterminate={indeterminate}
                            checked={checked}
                            onChange={onChange}
                            inputProps={{ "aria-label": "select all users" }}
                        />
                    </StyledTableCell>
                    <StyledTableCell>
                        {translate("Display Name")}
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        <div className={classes.combinedCells}>
                            <span>{translate("Email")}</span>
                            <span className={classes.cell}>
                                {translate("Slack Id")}
                            </span>
                        </div>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                        {translate("Date Of Birth")}
                    </StyledTableCell>
                    <StyledTableCell align="right">{""}</StyledTableCell>
                </TableRow>
            )}

            {numSelected > 0 && (
                <TableRow className={classes.toolbarContainer}>
                    <StyledTableCell padding="checkbox">
                        <Checkbox
                            indeterminate={indeterminate}
                            checked={checked}
                            onChange={onChange}
                            inputProps={{
                                "aria-label": "select all users",
                            }}
                        />
                    </StyledTableCell>
                    <StyledTableCell className={classes.title}>
                        {translate("<num> selected").replace(
                            "<num>",
                            numSelected
                        )}
                    </StyledTableCell>
                    <StyledTableCell>
                        <div className={classes.dateSettingsContainer}>
                            {renderDateSettings()}
                        </div>
                    </StyledTableCell>
                    <StyledTableCell />
                    <StyledTableCell align="right">
                        <Tooltip title={translate("Delete Users")}>
                            <IconButton
                                aria-label="delete"
                                onClick={() =>
                                    setIsDeleteConfirmationDialogOpen(true)
                                }
                                className={classes.icon}
                                disabled={isLoading}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={translate("Export to excel")}>
                            <IconButton
                                aria-label="export to excel"
                                onClick={() =>
                                    setIsExportConfirmationDialogOpen(true)
                                }
                                className={classes.icon}
                                disabled={isLoading}
                            >
                                <FileCopyIcon />
                            </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                </TableRow>
            )}
            <ConfirmationModal
                open={isDeleteConfirmationDialogOpen}
                handleClose={() => setIsDeleteConfirmationDialogOpen(false)}
                handleSuccess={onDeleteSelected}
                title={translate(`Delete User${numSelected > 1 ? "s" : ""}`)}
                message={translate(
                    `Are you sure you want to delete the selected user${
                        numSelected > 1 ? "s" : ""
                    }?`
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
            <ConfirmationModal
                open={isExportConfirmationDialogOpen}
                handleClose={() => setIsExportConfirmationDialogOpen(false)}
                handleSuccess={onExportSelected}
                title={translate(`Export selected Trackers`)}
                message={translate(
                    `Are you sure you want to export the selected trackers?`
                )}
                abortText={translate("No")}
                successText={translate("Yes")}
                isLoading={isLoading}
            />
        </TableHead>
    );
};

const UserTable = ({
    classes,
    users,
    onUserUpdate,
    handleSetSelectedIndexForTracker,
}) => {
    const dispatch = useDispatch();
    const [timeMin, setTimeMin] = useState(moment().startOf("day"));
    const [timeMax, setTimeMax] = useState(moment().endOf("day"));
    const [selected, setSelected] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const rowCount = useMemo(() => users.length, [users]);
    const numSelected = useMemo(() => selected.length, [selected]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = users.map((user) => user._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    const isSelected = (userId) => selected.indexOf(userId) !== -1;

    const onDeleteSelected = useCallback(async () => {
        setIsLoading(true);
        const results = [];
        const usersToDelete = selected.map(
            (userId) => users.find((user) => user._id === userId)._id
        );
        usersToDelete.forEach((userId) => {
            results.push(dispatch(deleteUser(userId)));
        });
        await Promise.all(results);
        if (results.filter(Boolean)) {
            dispatch({
                type: ADD_NOTIFICATION,
                payload: {
                    type: "SUCCESS",
                    message: translate("Deleted selected users successfully"),
                    size: "md",
                },
            });
            setSelected([]);
            onUserUpdate();
        }
        setIsLoading(false);
    }, [users, selected]);

    const handleCheckBoxClick = (event, userId) => {
        const selectedIndex = selected.indexOf(userId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, userId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const onExportToExcel = useCallback(async () => {
        setIsLoading(true);
        const usersToExport = selected.map(
            (userId) => users.find((user) => user._id === userId)._id
        );
        const _timeMin = new Date(timeMin.toISOString());
        const _timeMax = new Date(timeMax.toISOString());
        const fileName = `trackers.xlsx`;
        const query = `groupBy=day&gte=${_timeMin}&lte=${_timeMax}&pagination=false&fileName=${fileName}&userIds=${usersToExport.join(
            ","
        )}`;
        const res = await dispatch(getTrackersByUsersExcel(query));
        if (res) {
            downloadFileWithDepositionAttachment(res);
            dispatch(
                addToNotifications({
                    type: "SUCCESS",
                    message: translate("Downloading File!"),
                    size: "md",
                })
            );
        }
        setSelected([]);
        setIsLoading(false);
    }, [selected, users]);

    const renderDateSettings = useCallback(() => {
        const title = translate("Trackers from <start> to <end>");
        const getJSXElement = (type, index) => {
            switch (type) {
                case "<start>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            className={classes.datePicker}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMin}
                            disabled={isLoading}
                            onChange={(date, _) => setTimeMin(date)}
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            showTodayButton
                        />
                    );
                case "<end>":
                    return (
                        <DateTimePicker
                            key={index}
                            autoOk
                            ampm={false}
                            className={classes.datePicker}
                            cancelLabel={translate("Cancel")}
                            todayLabel={translate("Today")}
                            okLabel={translate("Ok")}
                            value={timeMax}
                            disabled={isLoading}
                            onChange={(date, _) => setTimeMax(date)}
                            format="yyyy/MM/dd HH:mm"
                            allowKeyboardControl={false}
                            showTodayButton
                        />
                    );
                default:
                    return <></>;
            }
        };

        const _title = reactStringReplace(
            title,
            /(<start>|<end>)/g,
            (match, i) => getJSXElement(match, i)
        );
        return _title.map((element, index) => {
            if (typeof element === "string")
                return (
                    <span className={classes.titleText} key={index}>
                        {element}
                    </span>
                );
            else return element;
        });
    }, [timeMin, timeMax, isLoading]);

    return (
        <TableContainer className={classes.table} id={"userContent"}>
            <Table aria-label="customized table">
                <TableToolbar
                    numSelected={numSelected}
                    handleDeleteSelected={onDeleteSelected}
                    handleExportToExcel={onExportToExcel}
                    isLoading={isLoading}
                    indeterminate={numSelected > 0 && numSelected < rowCount}
                    checked={rowCount > 0 && numSelected === rowCount}
                    onChange={handleSelectAllClick}
                    renderDateSettings={renderDateSettings}
                />

                <TableBody>
                    {users.map((user, index) => {
                        const isItemSelected = isSelected(user._id);
                        const labelId = `enhanced-table-check-box-${index}`;
                        return (
                            <UserCard
                                key={index}
                                user={user}
                                onUserUpdate={onUserUpdate}
                                isItemSelected={isItemSelected}
                                labelId={labelId}
                                handleClick={(event) =>
                                    handleCheckBoxClick(event, user._id)
                                }
                                handleSetSelectedIndexForTracker={
                                    handleSetSelectedIndexForTracker
                                }
                                usingDatePicker
                            />
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default UserTable;
