import React, { lazy, useMemo } from "react";
import { translate } from "../../../utils/i18n";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import uuid from "react-uuid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
    languageCodes,
    languageCodes_,
} from "../../../styles/constants";
import { useSelector } from "react-redux";
import VoiceSettingsPopper from "./VoiceSettingsPopper";
import Divider from "@material-ui/core/Divider";
const ImageUploader = lazy(() =>
    import("../../Customized Libraries/ReactImageUpload/ReactImageUpload")
);

const uploadURL = "/api/v1/uploads/";

export const MapSettings = ({ mapSettings, classes }) => {
    const language = useSelector((state) => state.language.currentLanguage);
    const {
        currentMap,
        selectedMapIndex,
        setCurrentMap,
        maps,
        photoRef,
        onDrop,
        addNewMap,
        deleteCurrentMap,
        setSelectedMapIndex,
        findMapFail,
        setFindMapFail,
        supportedLanguages,
    } = mapSettings;

    const defaultImage = useMemo(() => {
        if (currentMap?.map) {
            if (currentMap.map?.file) return [currentMap.map.file];
            else if (currentMap.map?.fileId)
                return [`${uploadURL}${currentMap.map.fileId}`];
        }
        return [];
    }, [currentMap]);

    return (
        <div>
            <div className={classes.container}>
                <div className={classes.title}>{translate("Maps")}</div>
            </div>
            <div
                className={classes.backgroundSelectorContainer}
                style={{ width: "95%", marginTop: "2.5em" }}
            >
                <div className={classes.subTitle} style={{width: "17em"}}>
                    {translate("Find map fail voice response")}:
                </div>
                <VoiceSettingsPopper
                    settings={findMapFail}
                    setSettings={setFindMapFail}
                    supportedLanguages={supportedLanguages}
                    fields={[]}
                />
            </div>
            <Divider variant="middle" className={classes.divider} />
            {maps.length > 0 && (
                <>
                    <div className={classes.backgroundSelectorContainer}>
                        <InputLabel className={classes.subTitle}>
                            {translate("Selected Map")}:
                        </InputLabel>
                        <Select
                            className={classes.qualitySelect}
                            style={{ width: "55%" }}
                            onChange={(e) =>
                                setSelectedMapIndex(e.target.value)
                            }
                            value={selectedMapIndex}
                        >
                            {maps.map((map, index) => (
                                <MenuItem value={index} key={uuid()}>
                                    {
                                        map.buttonLabel?.[
                                            languageCodes_[language]
                                        ]
                                    }
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div
                        className={classes.backgroundSelectorContainer}
                        style={{ width: "95%", marginTop: "2.5em" }}
                    >
                        <div
                            className={classes.subTitle}
                            style={{ marginRight: "2.1em" }}
                        >
                            {translate("Button Label")}:
                        </div>
                        <div className={classes.welcomeTextRoot}>
                            {supportedLanguages?.length > 0 &&
                                supportedLanguages.map((langCode, index) => (
                                    <div
                                        key={index}
                                        className={
                                            classes.welcomeTextSubContainer
                                        }
                                    >
                                        <div className={classes.welcomeText}>
                                            {langCode}
                                        </div>
                                        <TextField
                                            fullWidth
                                            placeholder={translate(
                                                "Button label",
                                                languageCodes[langCode]
                                            )}
                                            required
                                            value={
                                                currentMap?.buttonLabel?.[
                                                    langCode
                                                ] || ""
                                            }
                                            onChange={(e) =>
                                                setCurrentMap(
                                                    ["buttonLabel", langCode],
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                    <div
                        className={classes.backgroundSelectorContainer}
                        style={{ width: "95%", marginTop: "2.5em" }}
                    >
                        <div className={classes.subTitle}>
                            {translate("Voice Response")}:
                        </div>
                        <VoiceSettingsPopper
                            settings={currentMap}
                            setSettings={setCurrentMap}
                            supportedLanguages={supportedLanguages}
                            fields={["voiceResponse"]}
                        />
                    </div>
                    <div
                        className={classes.backgroundSelectorContainer}
                        style={{ width: "95%", marginTop: "2.5em" }}
                    >
                        <div
                            className={classes.subTitle}
                            style={{ marginRight: "3.4em" }}
                        >
                            {translate("Button Name")}:
                        </div>
                        <TextField
                            className={classes.server}
                            style={{ width: "18em" }}
                            placeholder={translate("Enter button name")}
                            required
                            value={currentMap?.buttonName}
                            onChange={(e) =>
                                setCurrentMap(["buttonName"], e.target.value)
                            }
                        />
                    </div>

                    <div ref={photoRef} style={{ width: "100%" }}>
                        <ImageUploader
                            withIcon={true}
                            withPreview={true}
                            buttonText={translate("Add Map")}
                            label={translate(
                                "Max file size: 50mb. Accepted files: jpg, jpeg, png, gif"
                            )}
                            fileSizeError={translate(
                                "<filename> file size is too big"
                            )}
                            fileTypeError={translate(
                                "<filename> is not a supported file extension"
                            )}
                            fileMaxError={translate(
                                "<filename> cannot be added, max files supported 1"
                            )}
                            onChange={onDrop}
                            defaultImages={defaultImage}
                            imgExtension={[".jpg", ".png", ".jpeg", ".gif"]}
                            maxFileSize={52428800}
                            removeDeletion={1}
                            singleImage
                        />
                    </div>
                </>
            )}
            <div
                style={{ width: "98%", display: "flex", justifyContent: "end" }}
            >
                {maps.length > 0 && (
                    <Button
                        onClick={deleteCurrentMap}
                        color="primary"
                        style={{ marginRight: "1em" }}
                    >
                        {translate("Delete current Map")}
                    </Button>
                )}
                <Button
                    onClick={addNewMap}
                    color="primary"
                    variant={"contained"}
                >
                    {translate("Add new Map")}
                </Button>
            </div>
        </div>
    );
};
