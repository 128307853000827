import en from "../images/america_resized.png";
import vn from "../images/vietnam.png";
import ja from "../images/japan.png";
import ko from "../images/south_korea.png";
import enLocale from "date-fns/locale/en-US";
import viLocale from "date-fns/locale/vi";
import jaLocale from "date-fns/locale/ja";
import koLocale from "date-fns/locale/ko";

import {
    aimeColor,
    blackColor,
    grayColor,
    whiteColor,
} from "./material-dashboard-react";
import { translate } from "../utils/i18n";

const colors = {
    primaryColor: aimeColor[2],
    secondaryColor: blackColor,
    primaryButtonTheme: aimeColor[0],
    secondaryButtonTheme: whiteColor,
    primaryButtonTextTheme: whiteColor,
    secondaryButtonTextTheme: grayColor[0],
};
const languageCodes = {
    english: "en-US",
    vietnamese: "vi-VN",
    japanese: "ja-JP",
    korean: "ko-KR",
};

const languageCodes_ = {
    [languageCodes.english]: "english",
    [languageCodes.vietnamese]: "vietnamese",
    [languageCodes.japanese]: "japanese",
    [languageCodes.korean]: "korean",
};

const languageDisplay = {
    english: "English",
    vietnamese: "Tiếng Việt",
    japanese: "日本語",
    korean: "한국인",
};

const languageIcons = {
    english: en,
    vietnamese: vn,
    japanese: ja,
    korean: ko,
};
const localeMap = {
    [languageCodes.english]: enLocale,
    [languageCodes.vietnamese]: viLocale,
    [languageCodes.japanese]: jaLocale,
    [languageCodes.korean]: koLocale,
};

const slack = {
    DELIVERY_STAFFS: [
        {
            name: "Thu",
            slack_id: "thu",
        },
        {
            name: "Hiền",
            slack_id: "hienlt",
        },
    ],
    DELIVERY_CHANNELS: ["reception_alert"],
    DELIVERY_MESSAGE: "Please go outside to receive mail/package",
    INTERVIEW_STAFFS: [
        {
            gender: "anh",
            name: "Huy",
            slack_id: "huypq",
        },
        {
            gender: "chị",
            name: "Hằng",
            slack_id: "hangnguyen",
        },
    ],
    INTERVIEW_CHANNELS: ["reception_alert"],
    INTERVIEW_MESSAGE: "Mr./Ms <name> is here for an interview",
    DEFAULT_MENTIONS: [
        {
            name: "Nguyễn Thị Ánh Thu",
            slack_id: "thu",
        },
        {
            name: "Lê Thanh Hiền",
            slack_id: "hienlt",
        },
        {
            name: "Phạm Quang Huy",
            slack_id: "huypq",
        },
        {
            name: "Nguyễn Thị Thu Hằng",
            slack_id: "hangnguyen",
        },
        {
            name: "Nguyễn Chính Hoàng",
            slack_id: "hoangnc",
        },
        {
            name: "Nguyễn Tuấn Hưng",
            slack_id: "hung",
        },
        {
            name: "Nguyễn Tuấn Anh",
            slack_id: "ntanh",
        },
        {
            name: "Nguyễn Tuấn Đức",
            slack_id: "duc",
        },
        {
            name: "Trịnh Văn Quân",
            slack_id: "quantv",
        },
        {
            name: "Praise Oketola Oluwabamise",
            slack_id: "Praise",
        },
        {
            name: "Nguyễn Minh Phương",
            slack_id: "phuongnm",
        },
    ],
    DEFAULT_CHANNELS: ["reception_alert"],
    DEFAULT_MESSAGE: "Mr./Ms. <name> wants to see you",
};

/**
 * Can get more google tts voice here https://cloud.google.com/text-to-speech/docs/voices
 * Browser voices across different browsers/ browser version. Should add to the list if voice is not included
 * */
const TTSVoices = {
    [languageCodes.english]: {
        browser: {
            male: ["Google UK English Male"],
            female: ["Google UK English Female"],
        },
        gapi: {
            male: "en-US-Wavenet-D",
            female: "en-US-Wavenet-F",
        },
    },
    [languageCodes.vietnamese]: {
        browser: {
            male: [],
            female: [],
        },
        gapi: {
            male: "vi-VN-Wavenet-B",
            female: "vi-VN-Standard-A",
            // female: "vi-VN-Wavenet-C",
        },
    },
    [languageCodes.japanese]: {
        browser: {
            male: [],
            female: ["Google 日本語"],
        },
        gapi: {
            male: "ja-JP-Standard-D",
            female: "ja-JP-Wavenet-B",
        },
    },
    [languageCodes.korean]: {
        browser: {
            male: [],
            female: ["Google 한국의"],
        },
        gapi: {
            male: "ko-KR-Wavenet-C",
            female: "ko-KR-Wavenet-A",
        },
    },
};

const languageSelectionLabels = [
    "Please select your language to begin",
    "Xin hãy chọn ngôn ngữ để bắt đầu",
    "言語を選択してください",
    "시작할 언어를 선택하십시오",
];

const ttsPostProcessWords = [
    {
        word: "aimesoft",
        [languageCodes.english]: [
            "insults",
            "I'm soft",
            "aimersoft",
            "I'm a soft",
            "Airsoft",
            "I miss hot",
            "Home-Start",
            "ainsoft",
            "m soft",
            "Amy soft",
            "emsisoft",
            "emsoft"
        ],
        [languageCodes.vietnamese]: [
            "adc",
            "Misa",
            "ministop",
            "abc",
            "imi shop",
            "army shop",
        ],
    },
    {
        word: "アイメソフト",
        [languageCodes.japanese]: [
            "アニメ ソフト",
            "アニメソフト",
            "IMEソフト",
            "IME ソフト",
            "菖蒲 ソフト",
            "菖蒲ソフト",
        ],
    },
    {
        /**
         * Regex only works if 'getOnlyName' option is equal True when speechRecognitionManager is initialized.
         *  Word field can be an array of regex or a string
         *  Language field takes the regex group index
         **/
        word: [/my name is (.+)/, /I am (.+)/],
        isRegex: true,
        [languageCodes.english]: { groupToReplace: 0, replaceGroupWith: 1 },
    },
    {
        word: [/(.+)です/, /(.+)と言います/, /(.+)と申します/, /(.+)ですが/],
        isRegex: true,
        [languageCodes.japanese]: { groupToReplace: 0, replaceGroupWith: 1 },
    },
];

const defaultVoiceResponses = {
    delivery: {
        after: {
            known: {
                [languageCodes.english]:
                    "Our staff got the message and will get back to you in a minute, please wait",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。",
                [languageCodes.korean]:
                    "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
            },
            unknown: {
                [languageCodes.english]:
                    "Our staff got the message and will get back to you in a minute, please wait",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。",
                [languageCodes.korean]:
                    "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
            },
        },
    },
    meeting: {
        meeting: {
            before: {
                known: {
                    [languageCodes.english]: "",
                    [languageCodes.vietnamese]: "",
                    [languageCodes.japanese]: "",
                    [languageCodes.korean]: "",
                },
                unknown: {
                    [languageCodes.english]: "Please tell me your name",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên của bạn",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をお聞かせくださいますか。",
                    [languageCodes.korean]: "당신의 이름을 알려주세요",
                },
            },
            after: {
                known: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                },
                unknown: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                },
            },
            onFindMeetingFail: {
                known: {
                    [languageCodes.english]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.vietnamese]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.japanese]:
                        "<name> 様。会議が見つかりませんでした。代わりにスタッフに連絡しますか",
                    [languageCodes.korean]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                },
                unknown: {
                    [languageCodes.english]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.vietnamese]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                    [languageCodes.japanese]:
                        "<name> 様。会議が見つかりませんでした。代わりにスタッフに連絡しますか",
                    [languageCodes.korean]:
                        "<name>, We couldn't find any meeting. Do you want to contact a staff",
                },
            },
            onFindMeetingSuccess: {
                known: {
                    [languageCodes.english]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.vietnamese]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.japanese]:
                        "<name>様のミーティングが見つかりました。ミーティングは<location>で<time>に行われます。少々お待ちください。",
                    [languageCodes.korean]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                },
                unknown: {
                    [languageCodes.english]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.vietnamese]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                    [languageCodes.japanese]:
                        "<name>様のミーティングが見つかりました。ミーティングは<location>で<time>に行われます。少々お待ちください。",
                    [languageCodes.korean]:
                        "We’ve found your meeting <name>. The meeting will take place in <location>, at <time>",
                },
            },
        },
        seeingSomeone: {
            before: {
                known: {
                    [languageCodes.english]: "Please tell me your name",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên của bạn",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をご確認くださいますか。",
                    [languageCodes.korean]: "당신의 이름을 알려주세요",
                },
                unknown: {
                    [languageCodes.english]: "Please tell me your name",
                    [languageCodes.vietnamese]:
                        "Xin vui lòng cho tôi biết tên của bạn",
                    [languageCodes.japanese]:
                        "かしこまりました。お名前をお聞かせくださいますか。",
                    [languageCodes.korean]: "당신의 이름을 알려주세요",
                },
            },
            after: {
                known: {
                    [languageCodes.english]:
                        "<name>, Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                },
                unknown: {
                    [languageCodes.english]:
                        "Our staff got the message and will get back to you in a minute, please wait",
                    [languageCodes.vietnamese]:
                        "Nhân viên của chúng tôi đã nhận được thông báo, bạn vui lòng đợi một chút",
                    [languageCodes.japanese]:
                        "係りの者がすぐ参りますので、こちらで少々お待ちください。",
                    [languageCodes.korean]:
                        "우리 직원이 메시지를 받았습니다. 잠시 후 연락드리겠습니다.",
                },
            },
        },
    },
    interview: {
        before: {
            known: {
                [languageCodes.english]: "Please tell me your name",
                [languageCodes.vietnamese]:
                    "Xin vui lòng cho tôi biết tên của bạn",
                [languageCodes.japanese]:
                    "かしこまりました。面接ですね。それではお名前を教えてください。",
                [languageCodes.korean]: "당신의 이름을 알려주세요",
            },
            unknown: {
                [languageCodes.english]: "Please tell me your name",
                [languageCodes.vietnamese]:
                    "Xin vui lòng cho tôi biết tên của bạn",
                [languageCodes.japanese]:
                    "かしこまりました。面接ですね。それではお名前を教えてください。",
                [languageCodes.korean]: "당신의 이름을 알려주세요",
            },
        },
        after: {
            known: {
                [languageCodes.english]:
                    "Our staff got the message, please wait a second",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được tin nhắn, bạn vui lòng đợi chút ạ.",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。リラックスしてくださいね。",
                [languageCodes.korean]:
                    "저희 직원이 메시지를 받았습니다. 잠시만 기다려 주십시오.",
            },
            unknown: {
                [languageCodes.english]:
                    "Our staff got the message, please wait a second",
                [languageCodes.vietnamese]:
                    "Nhân viên của chúng tôi đã nhận được tin nhắn, bạn vui lòng đợi chút ạ.",
                [languageCodes.japanese]:
                    "ありがとうございます。担当者が参りますので、少々お待ちください。リラックスしてくださいね。",
                [languageCodes.korean]:
                    "저희 직원이 메시지를 받았습니다. 잠시만 기다려 주십시오.",
            },
        },
    },
};

const defaultWelcomeText = {
    staff: {
        [languageCodes.english]: "Hello <name>",
        [languageCodes.vietnamese]: "Xin chào <name>",
        [languageCodes.japanese]: "こんにちは<name>さん",
        [languageCodes.korean]: "안녕하세요 <name>",
    },
    guest: {
        [languageCodes.english]: "Hello",
        [languageCodes.vietnamese]: "Xin chào",
        [languageCodes.japanese]: "こんにちは",
        [languageCodes.korean]: "안녕하세요",
    },
};

const defaultReceptionScreenVoice = {
    known: {
        [languageCodes.english]: "How can I help you?",
        [languageCodes.vietnamese]: "Tôi giúp được gì cho bạn?",
        [languageCodes.japanese]: "どんな御用でしょうか？",
        [languageCodes.korean]: "어떻게 도와 드릴까요?",
    },
    unknown: {
        [languageCodes.english]: "How can I help you?",
        [languageCodes.vietnamese]: "Tôi giúp được gì cho bạn?",
        [languageCodes.japanese]: "どんな御用でしょうか？",
        [languageCodes.korean]: "어떻게 도와 드릴까요?",
    },
};

const defaultOnFindMapFailResponse = {
    known: {
        [languageCodes.english]: "We couldn't find that map",
        [languageCodes.vietnamese]: "We couldn't find that map",
        [languageCodes.japanese]: "その位置データがありません",
        [languageCodes.korean]: "We couldn't find that map",
    },
    unknown: {
        [languageCodes.english]: "We couldn't find that map",
        [languageCodes.vietnamese]: "We couldn't find that map",
        [languageCodes.japanese]: "その位置データがありません",
        [languageCodes.korean]: "We couldn't find that map",
    },
};

export {
    slack,
    colors,
    languageCodes,
    languageCodes_,
    languageDisplay,
    languageIcons,
    TTSVoices,
    localeMap,
    languageSelectionLabels,
    ttsPostProcessWords,
    defaultVoiceResponses,
    defaultWelcomeText,
    defaultReceptionScreenVoice,
    defaultOnFindMapFailResponse,
};
